import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SearchBox from "../../search/searchBox";

import "./Header.css";

const MobileMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleOpen = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="mobile-menu-area fixed-top d-block d-sm-none">
      <nav
        className={`mobile-menu${menuOpen ? " mobile-menu-open" : ""}`}
        id="mobile-menu"
      >
        <div className="sidebar-nav">
          <ul className="nav side-menu">
            <li className="sidebar-search">
              <SearchBox
                placeholder={"Search..."}
                onSearch={toggleOpen}
                isMobile
              />
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/" onClick={toggleOpen}>
                HOME
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/category/us-news"
                onClick={toggleOpen}
              >
                US NEWS
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/category/entertainment"
                onClick={toggleOpen}
              >
                ENTERTAINMENT
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/category/tabloid"
                onClick={toggleOpen}
              >
                TABLOID
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/category/politics"
                onClick={toggleOpen}
              >
                POLITICS
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/category/business"
                onClick={toggleOpen}
              >
                BUSINESS
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/category/sports"
                onClick={toggleOpen}
              >
                SPORTS
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/category/technology"
                onClick={toggleOpen}
              >
                TECHNOLOGY
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/category/alternative"
                onClick={toggleOpen}
              >
                ALTERNATIVE
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/category/us-newspapers"
                onClick={toggleOpen}
              >
                US NEWSPAPERS
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/category/international"
                onClick={toggleOpen}
              >
                INTERNATIONAL
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/privacy-policy"
                onClick={toggleOpen}
              >
                PRIVACY POLICY
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <div className="container">
        <div className="top_header_icon">
          <span className="top_header_icon_wrap">
            <a
              target="_blank"
              href="https://twitter.com/headlineintime"
              title="Twitter"
            >
              <i className="fa fa-twitter"></i>
            </a>
          </span>
          <span className="top_header_icon_wrap">
            <a
              target="_blank"
              href="https://www.facebook.com/bignewsbook"
              title="Facebook"
            >
              <i className="fa fa-facebook"></i>
            </a>
          </span>
          <span className="top_header_logo_wrap">
            <a href="/">
              <img src="/images/logo-dark.png" alt="Headline In Time" />
            </a>
          </span>
        </div>
        <div
          id="showLeft"
          className={`nav-icon${menuOpen ? " open active" : ""}`}
          onClick={toggleOpen}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
