import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import {
  getCategoryUrl,
  getCategoryName,
  getCategoryBgColorClass,
} from '../../../shared/utils';

import NewsBox from '../../shared/newsBox/NewsBox';

import './CategoryNews.css';

const propTypes = {
  categoryId: PropTypes.number.isRequired,
  stories: PropTypes.array,
  onCommentClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  isAdmin: PropTypes.bool
}

const defaultProps = {
  stories: [],
  onCommentClick: () => { },
  onDeleteClick: () => { },
  isAdmin: false
}

const renderSlides = (props) => {
  const { stories, isAdmin, onDeleteClick } = props;
  const slides = [];

  for (let i = 0; i < stories.length; i += 5) {
    const slideStories = stories.slice(i, i + 5);
    const hero = slideStories[0];
    const others = slideStories.slice(1, 5);

    const slide = (
      <Slide>
        <div className="item">
          <Row>
            <Col sm={6} md={6}>
              <NewsBox
                story={hero}
                isHero
                showDescription
                descriptionLines={3}
                showGradientOverlay
                isAdmin={isAdmin}
                lazyLoadImage={!isAdmin}
                onDelete={onDeleteClick}
              />
            </Col>
            <Col sm={6} md={6}>
              <Row className="rn_block">
                {others.map((story) => (
                  <Col xs={6} md={6} sm={6} className="post-padding">
                    <NewsBox
                      story={story}
                      titlePosition={'below'}
                      titleSize={'small'}
                      titleLines={3}
                      showSiteLogo={false}
                      // showSiteName
                      isAdmin={isAdmin}
                      lazyLoadImage={!isAdmin}
                      onDelete={onDeleteClick}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </Slide>
    )

    slides.push(slide);
  }

  return slides;
}

const renderCategoryName = (categoryId) => (
  <NavLink to={`/category/${getCategoryUrl(categoryId)}`}>
    <h3 className="category-headding">{getCategoryName(categoryId)}</h3>
  </NavLink>
)

const CategoryNews = (props) => {
  const { categoryId, stories, onCommentClick, isAdmin, onDeleteClick } = props;

  const numberOfStories = stories.length;

  if (stories.length === 0) {
    return null;
  }

  if (numberOfStories === 1) {
    const story = stories[0];

    return (
      <div className="category-news articale-inner">
        {renderCategoryName(categoryId)}
        <div className={`headding-border ${getCategoryBgColorClass(categoryId)}`}></div>
        <NewsBox
          story={story}
          isHero
          showDescription
          onCommentClick={onCommentClick}
          titleLines={3}
          showGradientOverlay
          isAdmin={isAdmin}
          lazyLoadImage={!isAdmin}
          onDelete={onDeleteClick}
        />
      </div>
    )
  }

  if (numberOfStories === 3) {
    const hero = stories[0];
    const others = stories.slice(1, 3);

    return (
      <div className='category-news articale-inner'>
        {renderCategoryName(categoryId)}
        <div className={`headding-border ${getCategoryBgColorClass(categoryId)}`}></div>
        <NewsBox
          story={hero}
          isHero
          showDescription
          onCommentClick={onCommentClick}
          titleLines={3}
          showGradientOverlay
          isAdmin={isAdmin}
          lazyLoadImage={!isAdmin}
          onDelete={onDeleteClick}
        />
        {others.map(story => (
          <NewsBox
            story={story}
            className={'box-item'}
            orientation={'horizontal'}
            titleSize={'small'}
            onCommentClick={onCommentClick}
            // showSiteName
            isAdmin={isAdmin}
            lazyLoadImage={!isAdmin}
            onDelete={onDeleteClick}
          />
        ))}
      </div>
    )
  }

  if (numberOfStories === 7) {
    const hero = stories[0];
    const others = stories.slice(1, 7);

    return (
      <section className="category-news articale-inner">
        <Container>
          <Row>
            <Col sm={12}>
              {renderCategoryName(categoryId)}
              <div className="headding-border"></div>
            </Col>
          </Row>
          <Row>
            <Col sm={5} md={5}>
              <div className="home2-post">
                <NewsBox
                  story={hero}
                  isHero
                  titlePosition={'below'}
                  titleSize={'medium'}
                  showDescription
                  onCommentClick={onCommentClick}
                  onDelete={onDeleteClick}
                  showGradientOverlay
                  lazyLoadImage={!isAdmin}
                />
              </div>
            </Col>
            <Col sm={7} md={7}>
              <Row className="rn_block">
                {others.map((story) => (
                  <Col xs={6} md={4} sm={4} className="post-padding">
                    <NewsBox
                      story={story}
                      titlePosition={'below'}
                      titleSize={'small'}
                      showSiteLogo={false}
                      onCommentClick={onCommentClick}
                      onDelete={onDeleteClick}
                      lazyLoadImage={!isAdmin}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }

  if (isMobile) {
    const hero1 = stories[0];
    const others1 = stories.slice(1, 5);

    const hero2 = stories[5];
    const others2 = stories.slice(6, 10);

    return (
      <section className="category-news articale-inner row">
        <Container>
          <Row>
            <Col sm={12}>
              {renderCategoryName(categoryId)}
              <div className="headding-border"></div>
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={6}>
              <NewsBox
                story={hero1}
                isHero
                showDescription
                descriptionLines={3}
                showGradientOverlay
                isAdmin={isAdmin}
                lazyLoadImage={!isAdmin}
              />
            </Col>
            <Col sm={6} md={6}>
              <Row className="rn_block">
                {others1.map((story) => (
                  <Col xs={6} md={6} sm={6} className="post-padding">
                    <NewsBox
                      story={story}
                      titlePosition={'below'}
                      titleSize={'small'}
                      titleLines={3}
                      showSiteLogo={false}
                      // showSiteName
                      isAdmin={isAdmin}
                      lazyLoadImage={!isAdmin}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={6}>
              <NewsBox
                story={hero2}
                isHero
                showDescription
                descriptionLines={3}
                showGradientOverlay
                isAdmin={isAdmin}
                lazyLoadImage={!isAdmin}
              />
            </Col>
            <Col sm={6} md={6}>
              <Row className="rn_block">
                {others2.map((story) => (
                  <Col xs={6} md={6} sm={6} className="post-padding">
                    <NewsBox
                      story={story}
                      titlePosition={'below'}
                      titleSize={'small'}
                      titleLines={3}
                      showSiteLogo={false}
                      // showSiteName
                      isAdmin={isAdmin}
                      lazyLoadImage={!isAdmin}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }

  return (
    <section className="category-news articale-inner carousel">
      {renderCategoryName(categoryId)}
      <div className="headding-border"></div>
      <div id="content-slide-2" className="owl-carousel owl-theme">
        <CarouselProvider
          naturalSlideWidth={750}
          naturalSlideHeight={isMobile ? 2000 : 600}
          totalSlides={stories.length / 5}
          infinite
          touchEnabled={false}
          dragEnabled={!isAdmin}
        >
          <div className="owl-controls clickable">
            <div className="owl-buttons">
              <ButtonBack className="owl-prev">
                <i className="pe-7s-angle-left" />
              </ButtonBack>
              <ButtonNext className="owl-next">
                <i className="pe-7s-angle-right" />
              </ButtonNext>
            </div>
          </div>
          <Slider>
            {renderSlides(props)}
          </Slider>
        </CarouselProvider>
      </div>
    </section>
  );
}

CategoryNews.propTypes = propTypes;
CategoryNews.defaultProps = defaultProps;

export default CategoryNews;
