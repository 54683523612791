/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import DataGrid from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { FileUploader } from "react-drag-drop-files";

import moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

import { Container, Row, Col } from 'react-bootstrap';

import * as newsletterActions from '../../../redux/actions/newsletterActions';

import './NewsletterDailyActivityGraphPage.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
 
const customStyles = {
  content: {
    top: '40%',
    left: '40%',
    right: '40%',
    bottom: '40%',
    width: '400px',
  }
};

const defaultColumnProperties = {
  sortable: true,
};

const DateFormatter = (value) => {
  return value && moment(value).format("M/D");
}

const NumberFormatter = (value) => {
  return value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const CurrencyFormatter = (value) => {
  return value && `$${value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

const columns = [
  {
    key: 'date',
    name: 'Date',
    // width: 130,
    formatter({ row, column }) {
      return DateFormatter(row[column.key]);
    },
    summaryFormatter() {
      return <strong>Totals:</strong>
    }
  },
  {
    key: 'delivered',
    name: 'Delivered',
    // width: 130,
    formatter({ row, column }) {
      return NumberFormatter(row[column.key]);
    },
    summaryFormatter({ row }) {
      return <>{NumberFormatter(row.delivered)}</>;
    }
  },
  {
    key: 'opens',
    name: 'Opens',
    // width: 130,
    formatter({ row, column }) {
      return NumberFormatter(row[column.key]);
    },
    summaryFormatter({ row }) {
      return <>{NumberFormatter(row.opens)}</>;
    }
  },
  {
    key: 'impressions',
    name: 'Ad Impressions',
    // width: 130,
    formatter({ row, column }) {
      return NumberFormatter(row[column.key]);
    },
    summaryFormatter({ row }) {
      return <>{NumberFormatter(row.impressions)}</>;
    }
  },
  {
    key: 'clicks',
    name: 'Ad Clicks',
    // width: 130,
    formatter({ row, column }) {
      return NumberFormatter(row[column.key]);
    },
    summaryFormatter({ row }) {
      return <>{NumberFormatter(row.clicks)}</>;
    }
  },
  {
    key: 'revenue',
    name: 'Ad Revenue',
    // width: 130,
    formatter({ row, column }) {
      return CurrencyFormatter(row[column.key]);
    },
    summaryFormatter({ row }) {
      return <>{CurrencyFormatter(row.revenue)}</>;
    }
  },
].map(c => ({ ...c, ...defaultColumnProperties }));

const NewsletterDailyActivityGraphPage = (props) => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const ispOptions = useSelector((state) => state.newsletter.isps);
  const dailyActivity = useSelector((state) => state.newsletter.dailyActivity);

  const [loading, setLoading] = useState(false);
  const [importing, setImporting] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);

  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);

  const [isp, setIsp] = useState("All");

  const [activityData, setActivityData] = useState({
    labels: [],
    datasets: []
  });

  const [activityRows, setActivityRows] = useState([]);
  const [totalsRow, setTotalsRow] = useState({});

  const [currentSort, setCurrentSort] = useState({
    sortColumn: 'date',
    sortDirection: 'DESC'
  });

  const [sortColumns, setSortColumns] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const { getNewsletterIsps, getNewsletterDailyActivity } = newsletterActions;

    setLoading(true);

    Promise.all([
      dispatch(getNewsletterIsps(auth.token)),
      dispatch(getNewsletterDailyActivity(moment(startDate).format("MM-DD-YYYY"), moment(endDate).format("MM-DD-YYYY"), isp === "All" ? null : isp, auth.token))
    ])
      .catch((err) => {
        console.log(err.message);

        history.push({
          pathname: '/login',
          state: { redirect: '/admin/newsletterDailyActivityGraph' }
        });
      })
      .finally(() => { setLoading(false) });
  }, [auth.token, dispatch, history]);

  useEffect(() => {
    if (!dailyActivity || dailyActivity.length === 0) return;

    console.log("dailyActivity", dailyActivity);

    let labels = [];

    let requests = [];
    let delivered = [];
    let failed = [];
    let opens = [];
    let openRates = [];
    let visits = [];
    let impressions = [];
    let clicks = [];
    let revenue = [];

    let inboxPcts = [];
    let spamPcts = [];

    let activityRows = [];

    let totalsRow = {
      date: "Totals:",
      delivered: 0,
      opens: 0,
      impressions: 0,
      clicks: 0,
      revenue: 0
    };

    dailyActivity.forEach(a => {
      const date = new Date(a.date);

      const dateLabel = moment(date).format("M/D");

      labels.push(dateLabel);

      const {
        nbrRequests,
        nbrDelivered,
        nbrFailed,
        nbrOfOpens,
        nbrOfVisits,
        inboxPct,
        spamPct,
        nbrOfAdClicks,
        nbrOfAdImpressions,
        adRevenue
      } = a;

      requests.push(nbrRequests);
      delivered.push(nbrDelivered);
      failed.push(nbrFailed);
      opens.push(nbrOfOpens);
      openRates.push((nbrOfOpens / nbrDelivered) * 100);
      visits.push(nbrOfVisits);
      inboxPcts.push(inboxPct);
      spamPcts.push(spamPct);
      impressions.push(nbrOfAdImpressions);
      clicks.push(nbrOfAdClicks);
      revenue.push(adRevenue);

      activityRows.push({
        date,
        delivered: nbrDelivered,
        opens: nbrOfOpens,
        impressions: nbrOfAdImpressions,
        clicks: nbrOfAdClicks,
        revenue: adRevenue
      });

      totalsRow.delivered += nbrDelivered;
      totalsRow.opens += nbrOfOpens;
      totalsRow.impressions += nbrOfAdImpressions;
      totalsRow.clicks += nbrOfAdClicks;
      totalsRow.revenue += adRevenue;
    })

    // setActivityRows(activityRows);

    const { sortColumn, sortDirection } = currentSort;

    sortRows(activityRows, sortColumn, sortDirection);

    setTotalsRow(totalsRow);

    const commonDataSets = [
      {
        label: 'Delivered',
        yAxisID: 'left1',
        data: delivered,
        borderColor: 'darkGreen',
        backgroundColor: 'darkGreen',
        lineTension: 0.3
      },

      {
        label: 'Opens',
        yAxisID: 'left1',
        data: opens,
        borderColor: 'blue',
        backgroundColor: 'blue',
        lineTension: 0.3
      },
    ];

    const ispDataSets = [
      {
        label: 'Open Rates',
        yAxisID: 'right1',
        data: openRates,
        borderColor: 'lightBlue',
        backgroundColor: 'lightBlue',
        lineTension: 0.3
      },
      {
        label: 'Inbox %',
        yAxisID: 'right1',
        data: inboxPcts,
        borderColor: 'lightGreen',
        backgroundColor: 'lightGreen',
        lineTension: 0.3
      },
      {
        label: 'Spam %',
        yAxisID: 'right1',
        data: spamPcts,
        borderColor: 'lightRed',
        backgroundColor: 'lightRed',
        lineTension: 0.3
      },
      {
        yAxisID: 'left1',
        label: 'Failed',
        data: failed,
        borderColor: 'darkRed',
        backgroundColor: 'darkRed',
        lineTension: 0.3
      },
      {
        yAxisID: 'left1',
        label: 'Visits',
        data: visits,
        borderColor: 'orange',
        backgroundColor: 'orange',
        lineTension: 0.3
      },
    ]

    const adDataSets = [
      {
        label: 'Ad Impressions',
        yAxisID: 'left1',
        data: impressions,
        borderColor: 'darkRed',
        backgroundColor: 'darkRed',
        lineTension: 0.3
      },
      {
        label: 'Ad Clicks',
        yAxisID: 'right1',
        data: clicks,
        borderColor: 'orange',
        backgroundColor: 'orange',
        lineTension: 0.3
      },
      {
        label: 'Ad Revenue',
        yAxisID: 'right2',
        data: revenue,
        borderColor: 'lightGreen',
        backgroundColor: 'lightGreen',
        lineTension: 0.3
      },
    ];

    let datasets = [...commonDataSets];

    if (isp === "All") {
      datasets = datasets.concat(adDataSets);
    } else {
      datasets = datasets.concat(ispDataSets);
    }

    setActivityData({
      labels,
      datasets
    });

  }, [dailyActivity]);

  const [options, setOptions] = useState({
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (t, d) {
            console.log("t", t);
            if (t.dataset.label === "Ad Revenue") {
              return `${t.dataset.label}: $${Number(t.formattedValue).toFixed(2)}`;
            } else if (t.dataset.label === "Open Rates") {
              return `Open Rate: ${Number(t.formattedValue).toFixed(0)}%`
            } else {
              return `${t.dataset.label}: ${t.formattedValue}`;
            }
          }
        }
      },
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Newsletter Activity',
      },
    },
  })

  useEffect(() => {
    let scales = {
      left1: {
        type: 'linear',
        position: 'left'
      },
      right1: {
        type: 'linear',
        position: 'right',
        ticks: {
          beginAtZero: true
        }
      },
      right2: {
        type: 'linear',
        position: 'right',
        ticks: {
          beginAtZero: true,
          callback: function (value, index, values) {
            return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
          }
        }
      }
    };

    if (isp !== "All") {
      scales = {
        left1: {
          type: 'linear',
          position: 'left'
        },
        right1: {
          type: 'linear',
          position: 'right',
          ticks: {
            beginAtZero: true,
            callback: function (value, index, values) {
              return `${Number(value).toFixed(0)}%`;
            }
          }
        },
      }
    }

    setOptions({ ...options, scales });

  }, [isp]);

  const getIspOptions = () => {
    var options = [...ispOptions];

    options.unshift("All");

    return options;
  }

  const sortRows = (activityRows, sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    let sortedRows = sortDirection === "NONE" ? [...activityRows] : [...activityRows].sort(comparer);

    setActivityRows([...sortedRows]);
    setCurrentSort({ sortColumn, sortDirection });
  };

  const getComparator = (sortColumn) => {
    switch (sortColumn) {
      case 'date':
      case 'delivered':
      case 'opens':
      case 'impressions':
      case 'clicks':
      case 'revenue':
        return (a, b) => a[sortColumn] - b[sortColumn];
      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  }

  const sortedRows = () => {
    if (sortColumns.length === 0) return activityRows;

    const sortedRows = [...activityRows];

    sortedRows.sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }

      return 0;
    });

    return sortedRows;
  }

  const handleSortColumnChange = (columns) => {
    console.log(columns);
    setSortColumns([...columns]);
  }

  const onSubmit = () => {
    const { getNewsletterDailyActivity } = newsletterActions;

    setLoading(true);

    console.log("isp", isp);

    dispatch(getNewsletterDailyActivity(moment(startDate).format("MM-DD-YYYY"), moment(endDate).format("MM-DD-YYYY"), isp === "All" ? null : isp, auth.token))
      .catch((err) => {
        console.log(err.message);

        history.push({
          pathname: '/login',
          state: { redirect: '/admin/newsletterDailyActivityGraph' }
        });
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const onImport = () => {
    setImportModalOpen(true);
  }

  const onCloseImportModal = () => {
    setImportModalOpen(false);
  }

  const [importFile, setImportFile] = useState(null);

  function readFileDataAsText(file) {
    // const file = e.target.files[0];

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsText(file);
    });
  }

  const handleImportFileChange = (file) => {
    setImportFile(file);

    readFileDataAsText(file)
      .then((text) => {
        const { uploadNewsletterAdActivity } = newsletterActions;

        dispatch(uploadNewsletterAdActivity(text, auth.token))
          .then(() => {
            setImportModalOpen(false);

            onSubmit();
          })
      })
  }

  return (
    <Container className="newsletter-daily-activity">
      <ReactModal
        isOpen={importModalOpen}
        style={customStyles}
      >
        <div className="import-modal-container">
          <div className="close-button" onClick={onCloseImportModal}>X</div>
        </div>
        <FileUploader handleChange={handleImportFileChange} name="file" types={['CSV']} />
      </ReactModal>
      <Row>
        <Col style={{ width: '100%' }}>
          <div className="activity-controls">
            <span className="date-selectors">
              From&nbsp;
              <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
              To&nbsp;
              <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
            </span>
            <Dropdown
              className="isp-dropdown"
              options={getIspOptions()}
              onChange={(option) => setIsp(option.value)}
              value={isp}
              label={'Isp'}
            // placeholder="Select an isp"
            />
            <div className="cta-buttons">
              <button className="btn btn-style send" onClick={onSubmit}>{loading ? 'Loading...' : 'Submit'}</button>
              <button className="btn btn-style send" onClick={onImport}>{importing ? 'Importing...' : 'Import...'}</button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Line loading={loading} options={options} data={activityData} />
        </Col>
      </Row>
      <Row>
        <Col>
          <DataGrid
            rowKeyGetter={(row) => { return row.id; }}
            columns={columns}
            rows={sortedRows()}
            defaultColumnOptions={{
              sortable: true,
            }}
            // onRowsChange={handleRowChanged}
            sortColumns={sortColumns}
            onSortColumnsChange={handleSortColumnChange}
            summaryRows={[totalsRow]}
            className="daily-activity-grid"
          />
        </Col>
      </Row>
    </Container>
  )
}

export default NewsletterDailyActivityGraphPage;