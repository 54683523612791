/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Text } from "react-native";
import ReactImageAppear from "react-image-appear";
import LazyLoad from "react-lazyload";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Image from "./image";
import StarRatingComponent from "react-star-rating-component";
import { NavLink } from "react-router-dom";
import { isMobile } from "react-device-detect";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import ReactGA from "react-ga";
import editIcon from "../../../assets/images/edit_icon.png";
import checkIcon from "../../../assets/images/check_icon.png";
import closeIcon from "../../../assets/images/close_icon.png";

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";

import {
  getCategoryName,
  getCategoryBgColorClass,
  getCategoryColorClass,
  getCategoryUrl,
  formatDate,
  renderHTML,
  renderBadge,
  getImageUrl,
  getLinkUrl,
  nFormatter,
} from "../../../shared/utils";

import "./NewsBox.css";
import { p } from "react-dom-factories";

function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const NewsBox = (props) => {
  const {
    story,
    descriptionLines = 2,
    className = "",
    orientation = "vertical",
    isHero = false,
    titlePosition = "above",
    titleSize = "large",
    titleLines = null,
    titleEditable = false,
    onTitleChanged = () => {},
    showCategoryBadge = false,
    showSocial = false,
    zoomOnHover = false,
    showSiteLogo = false,
    showSiteName = false,
    showStats = true,
    useImageUrl = false,
    useLinkUrl = false,
    linkToExternalSite = true,
    midContentAd = null,
    showSelectButton = false,
    onSelect = () => {},
    showDeleteButton = true,
    onDelete = () => {},
    showStarRating = false,
    onRatingSelect = () => {},
    selectText = "Select",
    useReadMoreButton = false,
    lazyLoadImage = true,
    isAdmin = false,
  } = props;

  const [titleEditMode, setTitleEditMode] = useState(false);
  const [title, setTitle] = useState(story?.title);
  const [imageLoaded, setImageLoaded] = useState(false);

  const renderTitleText = (lines) => {
    const { id, title } = story;

    let titleLines = lines && Number(lines);

    if (!titleLines) {
      titleLines = 5;
    }

    return (
      <HTMLEllipsis
        id={id}
        unsafeHTML={title}
        maxLine={titleLines}
        ellipsis="..."
        basedOn="letters"
      />
    );
  };

  const renderDescriptionText = (text, lines) => {
    if (isMobile) {
      return (
        <HTMLEllipsis
          unsafeHTML={text}
          maxLine={lines || 50}
          ellipsis="..."
          basedOn="letters"
        />
      );
    }

    if (lines > 5) {
      return decodeHtml(text);
    }

    return (
      <Text
        style={{
          color: "#7e7e7e",
          fontFamily: "Lato,sans-serif",
          fontSize: "1rem",
          lineHeight: "1.5",
          fontWeight: 400,
          margin: "0 0 20px",
        }}
        numberOfLines={Number(lines)}
      >
        {decodeHtml(text)}
      </Text>
    );
  };

  const renderContentText = (text) => {
    if (midContentAd) {
      const paragraphs = text.split("</p>");

      if (paragraphs.length < 3) {
        return (
          <Text
            style={{
              color: "#7e7e7e",
              fontFamily: "Lato,sans-serif",
              fontSize: "1rem",
              lineHeight: "1.5",
              fontWeight: 400,
              margin: "0 0 20px",
            }}
          >
            {renderHTML(text)}
          </Text>
        );
      }

      const firstParagraph = paragraphs[0] + "</p>";
      const secondParagraph = paragraphs[1] + "</p>";
      const remainingParagraphs = paragraphs.slice(2).join("</p>");

      return (
        <Text
          style={{
            color: "#7e7e7e",
            fontFamily: "Lato,sans-serif",
            fontSize: "1rem",
            lineHeight: "1.5",
            fontWeight: 400,
            margin: "0 0 20px",
          }}
        >
          {renderHTML(firstParagraph)}
          {renderHTML(secondParagraph)}
          {midContentAd}
          {renderHTML(remainingParagraphs)}
        </Text>
      );
    } else {
      return (
        <Text
          style={{
            color: "#7e7e7e",
            fontFamily: "Lato,sans-serif",
            fontSize: "1rem",
            lineHeight: "1.5",
            fontWeight: 400,
            margin: "0 0 20px",
          }}
        >
          {renderHTML(text)}
        </Text>
      );
    }
  };

  const getImageLinkUrl = () => {
    if (useImageUrl && story.imageUrl) {
      return story.imageUrl;
    }

    return getImageUrl(story.urlFriendlyName);
  };

  const getStoryUrl = () => {
    if (!linkToExternalSite) {
      return "#";
    }

    if (useLinkUrl) {
      return story.linkUrl;
    }

    return getLinkUrl(story);
  };

  const renderTitle = (lines) => {
    switch (titleSize) {
      case "small":
        if (isAdmin || !linkToExternalSite) {
          return <h5>{renderTitleText(lines)}</h5>;
        }

        return (
          <h5>
            <a href={getStoryUrl()}>{renderTitleText(lines)}</a>
          </h5>
        );

      case "medium":
        if (isAdmin || !linkToExternalSite) {
          return <h4>{renderTitleText(lines)}</h4>;
        }

        return (
          <h4>
            <a href={getStoryUrl()}>{renderTitleText(lines)}</a>
          </h4>
        );

      case "large":
      default:
        if (isAdmin || !linkToExternalSite) {
          return <h3>{renderTitleText(lines)}</h3>;
        }

        return (
          <h3>
            <a href={getStoryUrl()}>{renderTitleText(lines)}</a>
          </h3>
        );
    }
  };

  const onEditClick = () => {
    onTitleChanged(title);

    setTitleEditMode(false);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const renderStoryImage = (imageClass) => {
    if (!lazyLoadImage) {
      return <img src={getImageLinkUrl()} alt={story} className={imageClass} />;
    }

    return (
      <>
        {!imageLoaded && <Skeleton height={300} width={400} />}
        <img
          src={getImageLinkUrl()}
          alt={story}
          className={imageClass}
          onLoad={() => setImageLoaded(true)}
        />
      </>
    );


    if (!lazyLoadImage) {
      return <img src={getImageLinkUrl()} alt={story} className={imageClass} />;
    }

    return (
      <LazyLoad height={100} offset={200}>
        <Image imageUrl={getImageLinkUrl()} />
      </LazyLoad>
    );
  };

  const renderStoryThumbnail = () => {
    if (!lazyLoadImage) {
      return (
        <img
          className="entry-thumb"
          src={getImageLinkUrl()}
          alt=""
          height="70"
          width="100"
        />
      );
    }

    return (
      <LazyLoad height={70} width={100} offset={100}>
        <ReactImageAppear
          src={getImageLinkUrl()}
          placeholder
          className="entry-thumb"
          showLoader={false}
          // animation="bounceIn"
        />
      </LazyLoad>
    );
  };

  const renderImage = (imageUrl, height, width) => {
    const { lazyLoadImage } = props;

    if (!lazyLoadImage) {
      return (
        <img
          src={imageUrl}
          alt={"img"}
          height={height || "auto"}
          width={width || "auto"}
        />
      );
    }

    return (
      <LazyLoad height={height || 100} offset={200}>
        <img
          src={imageUrl}
          className={className}
          alt={"img"}
          height={height || "auto"}
          width={width || "auto"}
        />
      </LazyLoad>
    );
  };

  const renderSocial = () => {
    return (
      <div className="social">
        <ul>
          <li>
            <FacebookShareButton
              url={story.linkUrl}
              children={
                <a
                  onClick={() => trackShareClickEvent("facebook")}
                  href="#"
                  className="facebook"
                >
                  <i class="fa fa-facebook"></i>
                  <span>{nFormatter(story.shares)}</span>
                </a>
              }
            />
          </li>
          <li>
            <TwitterShareButton
              url={getLinkUrl(story)}
              children={
                <a
                  onClick={() => trackShareClickEvent("twitter")}
                  href="#"
                  className="twitter"
                >
                  <i class="fa fa-twitter"></i>
                </a>
              }
            />
          </li>
          <li>
            <EmailShareButton
              url={getLinkUrl(story)}
              children={
                <a
                  onClick={() => trackShareClickEvent("email")}
                  href="#"
                  className="google"
                >
                  <i class="fa fa-envelope"></i>
                </a>
              }
              subject={decodeHtml(story.title)}
            />
          </li>
        </ul>
      </div>
    );
  };

  const renderCommentsCaption = () => {
    if (story.comments === 0) return;

    return (
      <div className="post-author-comment">
        <i className="pe-7s-comment" />
        {nFormatter(story.comments)}
      </div>
    );
  };

  const renderTime = () => {
    const { timeAgo, createDate } = story;

    return (
      <div className="post-date">
        <i className="pe-7s-clock"></i>
        <span className="date-time">{timeAgo || formatDate(createDate)}</span>
      </div>
    );
  };

  const renderDeleteButton = () => {
    if (!showDeleteButton || !isAdmin) return;

    return (
      <div className="delete-button" onClick={handleDelete}>
        <img className="delete-icon" src={closeIcon} alt="delete" />
      </div>
    );
  };

  const handleDelete = () => {
    onDelete(story);
  };

  const trackStoryClickEvent = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Story Link",
      label: getStoryUrl(),
    });
  };

  const trackShareClickEvent = (social) => {
    console.log(`clicked ${social} share`);

    ReactGA.event({
      category: "User",
      action: "Clicked Share Button",
      label: social,
    });
  };

  if (!story) {
    return null;
  }

  const { categoryId } = story;

  let imageContainerClass = "post-thumb";

  if (isHero) {
    imageContainerClass += " hero-image";
  }

  if (zoomOnHover) {
    imageContainerClass += " img-zoom-in";
  }

  const imageClass = titlePosition === "inside" ? "" : "img-fluid";

  if (orientation === "horizontal") {
    if (titleSize === "small") {
      return (
        <div className="news-box">
          <div
            className="box-item wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <div className="img-thumb">
              {renderDeleteButton()}
              <a
                onClick={trackStoryClickEvent}
                href={getStoryUrl()}
                rel="bookmark"
              >
                {renderStoryThumbnail()}
              </a>
            </div>
            <div className="item-details">
              {showCategoryBadge && (
                <h6
                  className={`sub-category-title ${getCategoryBgColorClass(
                    story.categoryId
                  )}`}
                >
                  <NavLink to={`/category/${getCategoryUrl(story.categoryId)}`}>
                    {getCategoryName(story.categoryId)}
                  </NavLink>
                </h6>
              )}
              <h3 className="td-module-title">
                <a onClick={trackStoryClickEvent} href={getStoryUrl()}>
                  {renderTitleText(titleLines || 3)}
                </a>
              </h3>
              {showSiteName && story.site && (
                <div className="post-author-name">{story.site.name}</div>
              )}
              {showStats && (
                <div className="post-editor-date">
                  {renderTime()}
                  {renderCommentsCaption()}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="news-box">
        <div className="post-style2 wow fadeIn" data-wow-duration="1s">
          <div className={imageContainerClass}>
            {renderDeleteButton()}
            <a onClick={trackStoryClickEvent} href={getStoryUrl()}>
              <div
                className={
                  titlePosition === "inside" || showSiteLogo ? "gradient" : ""
                }
              >
                {renderStoryImage()}
              </div>
            </a>
            {showSiteLogo && story.site && (
              <div className={`site-logo ${titleSize}`}>
                {renderImage(`/images/siteLogos/${story.site.logoImage}`)}
              </div>
            )}
          </div>
          <div
            id={story.id}
            className={`post-style2-detail${
              titleEditable && " title-editable"
            }`}
          >
            {titlePosition === "above" && !titleEditMode && (
              <div className="title-text">
                {renderTitle(story, titleSize, titleLines)}
              </div>
            )}
            {titleEditable && (
              <div className="edit-title">
                {!titleEditMode && (
                  <img
                    src={editIcon}
                    alt="edit"
                    width="20"
                    onClick={() => {
                      setTitleEditMode(true);
                    }}
                  />
                )}
                {titleEditMode && (
                  <img
                    src={checkIcon}
                    alt="save"
                    width="20"
                    onClick={(story) => onEditClick(story)}
                  />
                )}
                {titleEditMode && (
                  <textarea
                    className="title-text-input"
                    type="text"
                    size={20}
                    // placeholder="keywords"
                    value={decodeHtml(title)}
                    onChange={handleTitleChange}
                    // onChange={(e) => handleKeywordChange(story, e)}
                  />
                )}
              </div>
            )}
            {showSiteName && story.site && (
              <div className="post-author-name">{story.site.name}</div>
            )}
            {showStats && (
              <div className="date">
                <div className="post-editor-date">
                  {renderTime(story)}
                  {renderCommentsCaption(story)}
                </div>
              </div>
            )}
            {story.content && <p>{renderContentText(story.content)}</p>}
            {!story.content && (
              <div>
                <p>
                  {renderDescriptionText(story.description, descriptionLines)}
                </p>
                {!showSelectButton && !showStarRating && (
                  <a
                    onClick={() => trackStoryClickEvent(story)}
                    href={getStoryUrl()}
                  >
                    <button type="button" className="btn btn-style">
                      Read more
                    </button>
                  </a>
                )}
              </div>
            )}

            {showSelectButton && (
              <button
                type="button"
                className="btn btn-style"
                onClick={onSelect}
              >
                {selectText}
              </button>
            )}
            {showStarRating && (
              <div className="star-rating-container">
                <StarRatingComponent
                  name={story.id}
                  starCount={7}
                  value={story.starRating}
                  onStarClick={onRatingSelect}
                />
                {story.selected && (
                  <button
                    type="button"
                    className="btn btn-style"
                    onClick={onSelect}
                  >
                    Unselect
                  </button>
                )}
                {!story.selected && story.starRating > 0 && (
                  <button
                    type="button"
                    className="btn btn-style unselected"
                    onClick={onSelect}
                  >
                    Select
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="news-box">
      <div
        className={`${className} post-wrapper wow fadeIn`}
        data-wow-duration="2s"
      >
        {titlePosition === "above" && renderTitle(story, titleSize, titleLines)}
        <div className={imageContainerClass}>
          {renderDeleteButton()}
          <a onClick={() => trackStoryClickEvent(story)} href={getStoryUrl()}>
            <div
              className={
                titlePosition === "inside" || showSiteLogo ? "gradient" : ""
              }
            >
              {renderStoryImage(imageClass)}
            </div>
          </a>
          {showSiteLogo && story.site && (
            <div className={`site-logo ${titleSize}`}>
              {renderImage(`/images/siteLogos/${story.site.logoImage}`)}
            </div>
          )}
        </div>
        {showCategoryBadge && (
          <div className="post-info meta-info-rn">
            <div className="slide">
              {story.categoryId && renderBadge(story.categoryId)}
            </div>
          </div>
        )}
        {titlePosition === "inside" && (
          <div className="post-info">
            <span className={getCategoryColorClass(categoryId)}>
              {getCategoryName(categoryId)}
            </span>
            <h3 className="post-title">
              <a
                onClick={() => trackStoryClickEvent(story)}
                href={getStoryUrl()}
                rel="bookmark"
              >
                {renderHTML(story.title)}
              </a>
            </h3>
            {showStats && (
              <div className="post-editor-date">
                {renderTime(story)}
                {renderCommentsCaption(story)}
                <a
                  onClick={() => trackStoryClickEvent(story)}
                  className="readmore pull-right"
                  href={getStoryUrl()}
                >
                  <i className="pe-7s-angle-right" />
                </a>
              </div>
            )}
          </div>
        )}
      </div>
      {showSocial && renderSocial(story)}
      {titlePosition !== "inside" && (
        <div className="post-title-author-details">
          {titlePosition === "below" &&
            renderTitle(story, titleSize, titleLines)}
          {showSiteName && story.site && (
            <div className="post-author-name">{story.site.name}</div>
          )}
          {showStats && (
            <div className="post-editor-date">
              {renderTime(story)}
              {renderCommentsCaption(story)}
            </div>
          )}
          {story.content && <p>{renderContentText(story.content)}</p>}
          {!story.content && (
            <p>
              {renderDescriptionText(story.description, descriptionLines)}
              <a
                className={useReadMoreButton && "center-button"}
                onClick={() => trackStoryClickEvent(story)}
                href={getStoryUrl(story)}
              >
                {useReadMoreButton && (
                  <button type="button" className="btn btn-style">
                    Read more
                  </button>
                )}
                {!useReadMoreButton && <span>Read more...</span>}
                {showSelectButton && (
                  <button
                    type="button"
                    className="btn btn-style"
                    onClick={onSelect}
                  >
                    {selectText}
                  </button>
                )}
              </a>
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default NewsBox;
