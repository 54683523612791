import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { Route, Switch } from "react-router-dom";
import { isMobile } from "react-device-detect";
import HomePage from "./home/HomePage";
import LoginPage from "./login/LoginPage";
import StoryPage from "./story/StoryPage";
import CategoryPage from "./category/CategoryPage";
import SearchResultsPage from "./search/SearchResultsPage";
import PrivacyPolicyPage from "./privacy-policy/PrivacyPolicyPage";
import SubscribePage from "./subscribe/SubscribePage";
import NewsletterConfirmPage from "./newsletter/NewsletterConfirmPage";
import ManageNewsletterPage from "./admin/manageNewsletter/ManageNewsletterPage";
import SendNewsletterPage from "./admin/sendNewsletter/SendNewsletterPage";
import NewsletterActivityPage from "./admin/newsletterActivity/NewsletterActivityPage";
import NewsletterDailyActivityGraphPage from "./admin/analytics/NewsletterDailyActivityGraphPage";
import AboutPage from "./about/AboutPage";
import Header from "./common/header/Header";
import Scroller from "./common/scroller/Scroller";
import Footer from "./common/footer/Footer";
import MobileBottomAd from "./shared/googleAds/mobileBottomAd";

import "./App.css";
import PageNotFound from "./PageNotFound";
import UnsubscribePage from "./unsubscribe/UnsubscribePage";
import UpdatePreferencesPage from "./updatePreferences/UpdatePreferencesPage";

const App = (props) => {
  const { store } = props;

  ReactGA.initialize("UA-58777467-1");

  const [isAdmin, setIsAdmin] = useState(false);
  const [displayMode, setDisplayMode] = useState("user");

  useEffect(() => {
    if (store) {
      store.subscribe(() => {
        setAdmin(store);
      });
    }
  }, []);

  const setAdmin = (store) => {
    const state = store.getState();
    const isAdmin =
      state.auth && state.auth.data && state.auth.data.role === "admin";

    setIsAdmin(isAdmin);
    setDisplayMode(isAdmin ? "admin" : "user");
  };

  const onDisplayModeChange = (displayMode) => {
    setDisplayMode(displayMode);
  };

  return (
    <div>
      <Header onDisplayModeChange={onDisplayModeChange} />
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <HomePage displayMode={displayMode} />}
        />
        <Route path="/login" component={LoginPage} />
        <Route path="/category/all-news" component={HomePage} />
        <Route
          path="/category/:categoryUrl/:pageNumber?"
          component={CategoryPage}
        />
        <Route
          path="/search/:keyword/:pageNumber?"
          component={SearchResultsPage}
        />
        <Route path="/about" component={AboutPage} />
        <Route path="/user/unsubscribe/:email" component={UnsubscribePage} />
        <Route
          path="/user/update-preferences/:email?"
          component={UpdatePreferencesPage}
        />
        <Route
          path="/newsletter/confirm/:email"
          component={NewsletterConfirmPage}
        />
        <Route path="/user/confirm/:email" component={HomePage} />
        <Route
          path="/story/:storyUrl/:storyEmail?/:newsletterId?/:emailServiceProvider?"
          component={StoryPage}
        />
        <Route
          path="/admin/manageNewsletter"
          component={ManageNewsletterPage}
        />
        <Route path="/admin/sendNewsletter" component={SendNewsletterPage} />
        <Route
          path="/admin/newsletterActivity"
          component={NewsletterActivityPage}
        />
        <Route
          path="/admin/newsletterDailyActivityGraph"
          component={NewsletterDailyActivityGraphPage}
        />
        <Route path="/Default.aspx" component={HomePage} />
        <Route path="/privacy-policy" component={PrivacyPolicyPage} />
        <Route path="/subscribe" component={SubscribePage} />

        <Route component={PageNotFound} />
      </Switch>
      <Footer />
      <Scroller />
      {isMobile && <MobileBottomAd adSlot={"1109964518"} />}
    </div>
  );
};

export default App;
