import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useHistory, useParams } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import ReactGA from "react-ga";
import Sticky from "react-sticky-el";
import Disqus from "disqus-react";
import GoogleAd from "../shared/googleAds";
import RevContentAd from "../shared/revContentAds";
import Categories from "../shared/categories";
import RelatedNews from "./relatedNews";
import PopularViewedNews from "../shared/popularViewedNews";
import NewsBox from "../shared/newsBox/NewsBox";
import SearchBox from "../search/searchBox";
import * as headlineActions from "../../redux/actions/headlineActions";
import * as categoryActions from "../../redux/actions/categoryActions";
import * as userActions from "../../redux/actions/userActions";

import "./StoryPage.css";
import { getLinkUrl } from "../../shared/utils";

const StoryPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    storyUrl: storyUrlParam,
    storyEmail,
    newsletterId,
    emailServiceProvider,
  } = useParams();

  const { article, categories, mostPopular, mostViewed, relatedNews } =
    useSelector((state) => ({
      article: state.headlines.story.article,
      categories: state.categories,
      mostPopular: state.headlines.story.mostPopular,
      mostViewed: state.headlines.story.mostViewed,
      relatedNews: state.headlines.story.relatedNews,
    }));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [storyUrl, setStoryUrl] = useState(storyUrlParam);

  useEffect(() => {
    let path = "/story";

    if (storyUrlParam) {
      path += `/${storyUrlParam}`;
    }

    if (storyUrl) {
      if (storyEmail) {
        dispatch(
          userActions.saveUserVisit(
            storyEmail,
            newsletterId,
            emailServiceProvider
          )
        );
        history.push(path);
      }

      setLoading(true);

      dispatch(categoryActions.getCategories()).catch(() => {
        setError(true);
      });

      dispatch(headlineActions.getHeadline(storyUrl))
        .then(() => {
          ReactGA.pageview(path);
        })
        .catch(() => {
          setError(true);
          ReactGA.pageview(`${path}/error`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  if (loading) {
    return <div id="se-pre-con"></div>;
  }

  if (error) {
    return (
      <div className="fade-in">
        <div className="story-page">
          <Container>
            <Row>
              <Col sm={12}>
                <div className="error-message">
                  <h1>Sorry, something went wrong on our end.</h1>
                  <a href={storyUrl}>Retry</a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  if (!article) {
    return null;
  }

  const { content } = article;

  const disqusShortname = "headlineintime";
  const disqusConfig = {
    url: getLinkUrl(article),
    identifier: article.id,
    title: article.title,
  };

  return (
    <div className="fade-in">
      <div className="story-page">
        <ToastContainer />
        <Container>
          <Row>
            <Col sm={8}>
              <article className="content">
                <NewsBox
                  story={article}
                  isHero
                  titlePosition={"below"}
                  titleSize={"large"}
                  showDescription
                  showSiteLogo={!content}
                  descriptionLines={50}
                  showGradientOverlay
                  showSocial
                  useImageUrl
                  useLinkUrl={!content}
                  linkToExternalSite={!content}
                  midContentAd={<RevContentAd widgetId={"140332"} />}
                  useReadMoreButton
                  lazyLoadImage
                />
                <RevContentAd widgetId={"120448"} />
                {/* {article.id > 0 && (
                  <Disqus.DiscussionEmbed
                    shortname={disqusShortname}
                    config={disqusConfig}
                  />
                )} */}
              </article>
              {relatedNews.length > 5 && (
                <RelatedNews stories={relatedNews.slice(0, 6)} />
              )}
              <RevContentAd widgetId={"120450"} />
              {relatedNews.length > 11 && (
                <RelatedNews stories={relatedNews.slice(6, 12)} />
              )}
            </Col>
            <Col sm={4} className="left-padding right-rail">
              <aside className="sidebar">
                <SearchBox />
                <GoogleAd showTitle adSlot={"5493026687"} maxHeight={"250px"} />
                <PopularViewedNews
                  popularStories={mostPopular}
                  viewedStories={mostViewed}
                />
                {isMobile && (
                  <GoogleAd adSlot={"3035169094"} maxHeight={"250px"} />
                )}
                <GoogleAd
                  showTitle
                  adSlot={"4161600854"}
                  minHeight={isMobile ? "150px" : "600px"}
                />
                <Categories categories={categories} />
                {!isMobile && (
                  <Sticky
                    topOffset={-50}
                    boundaryElement={".right-rail"}
                    hideOnBoundaryHit={false}
                  >
                    <div className="sticky-google-ad-right">
                      <GoogleAd
                        showTitle
                        adSlot={"4161600854"}
                        minHeight={"600px"}
                      />
                    </div>
                  </Sticky>
                )}
                {isMobile && (
                  <GoogleAd adSlot={"4161600854"} minHeight={"150px"} />
                )}
              </aside>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default withRouter(StoryPage);
