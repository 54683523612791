import React from "react";
import { withRouter } from "react-router";
import { Container, Row, Col } from "react-bootstrap";

import SubscribeForm from "../common/footer/subscribeForm";

import "./SubscribePage.css";

const SubscribePage = () => {
  return (
    <div className="fade-in">
      <div className="subscribe-page">
        <section className="block-inner">
          <Container>
            <Row>
              <Col sm={12}>
                <h1>Subscribe</h1>
                <div className="breadcrumbs">
                  <ul>
                    <li>
                      <i className="pe-7s-home"></i>{" "}
                      <a href="/" title="">
                        Home
                      </a>
                    </li>
                    <li>Subscribe</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="subscribe-page-inner">
          <Container>
            <Row>
              <Col sm={12}>
                <SubscribeForm />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default withRouter(SubscribePage);
