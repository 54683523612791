export const GET_HOME_PAGE_NEWS = "GET_HOME_PAGE_NEWS";
export const GET_TOP_NEWS = "GET_TOP_NEWS";
export const GET_RECENT_NEWS = "GET_RECENT_NEWS";
export const GET_MOST_POPULAR_NEWS = "GET_MOST_POPULAR_NEWS";
export const GET_MOST_VIEWED_NEWS = "GET_MOST_VIEWED_NEWS";
export const GET_CATEGORY_NEWS = "GET_CATEGORY_NEWS";
export const GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS";
export const GET_HEADLINE = "GET_HEADLINE";
export const GET_HEADLINE_AI_CONTENT = "GET_HEADLINE_AI_CONTENT";
export const GET_NEWSLETTER_CANDIDATES = "GET_NEWSLETTER_CANDIDATES";
export const GET_NEWSLETTER = "GET_NEWSLETTER";
export const GET_NEWSLETTERS = "GET_NEWSLETTERS";
export const GET_NEWSLETTER_SEND_ACTIVITY = "GET_NEWSLETTER_SEND_ACTIVITY";
export const GET_NEWSLETTER_FAILS = "GET_NEWSLETTER_FAILS";
export const GET_NEWSLETTER_ISPS = "GET_NEWSLETTER_ISPS";
export const GET_NEWSLETTER_ISP_ACTIVITY = "GET_NEWSLETTER_ISP_ACTIVITY";
export const GET_NEWSLETTER_DAILY_ACTIVITY = "GET_NEWSLETTER_DAILY_ACTIVITY";
export const GET_NEWSLETTER_ISP_COUNTS = "GET_NEWSLETTER_ISP_COUNTS";
export const GET_NEWSLETTER_ISP_BOX_PLACEMENTS = "GET_NEWSLETTER_ISP_BOX_PLACEMENTS";
export const GET_NEWSLETTER_SEND_COUNTS = "GET_NEWSLETTER_SEND_COUNTS";
export const GET_NEWSLETTER_SPAM_REPORT = "GET_NEWSLETTER_SPAM_REPORT";
export const SAVE_NEWSLETTER = "SAVE_NEWSLETTER";
export const SAVE_NEWSLETTER_ARTICLE = "SAVE_NEWSLETTER_ARTICLE";
export const SAVE_NEWSLETTER_SEND_COUNTS = "SAVE_NEWSLETTER_SEND_COUNTS";
export const SEND_NEWSLETTER = "SEND_NEWSLETTER";
export const GENERATE_SUBJECT = "GENERATE_SUBJECT";
export const GENERATE_CONTENT = "GENERATE_CONTENT";
export const SCHEDULE_NEWSLETTER = "SCHEDULE_NEWSLETTER";
export const SEND_TEST_NEWSLETTER = "SEND_TEST_NEWSLETTER";
export const CLEAR_CATEGORY = "CLEAR_CATEGORY";
export const GET_USER_COUNTS = "SUBSCRIBE_USER";
export const SUBSCRIBE_USER = "SUBSCRIBE_USER";
export const SAVE_USER_VISIT = "SAVE_USER_VISIT";
export const UNSUBSCRIBE_USER = "UNSUBSCRIBE_USER";
export const UPDATE_USER_NEWSLETTER_FREQUENCY = "UPDATE_USER_NEWSLETTER_FREQUENCY";
export const GET_FEATURED_NEWS = "GET_FEATURED_NEWS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_SEARCH_SUGGESTIONS = "GET_SEARCH_SUGGESTIONS";
export const CLEAR_SEARCH_SUGGESTIONS = "CLEAR_SEARCH_SUGGESTIONS";
export const GET_SITES = "GET_SITES";
export const REGISTER = "REGISTER";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET";
export const MAKE_UNIQUE = "MAKE_UNIQUE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const UPDATE_STAR_RATING = "UPDATE_STAR_RATING";
export const UPDATE_TITLE = "UPDATE_TITLE";