import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useHistory, useParams } from "react-router";
import { isMobile } from "react-device-detect";
import { Container, Row, Col } from "react-bootstrap";
import Pagination from "react-js-pagination";
import ReactGA from "react-ga";

import * as headlineActions from "../../redux/actions/headlineActions";
import GoogleAd from "../shared/googleAds";
import NewsBox from "../shared/newsBox/NewsBox";
import SearchBox from "../search/searchBox";
import PopularViewedNews from "../shared/popularViewedNews";

import "./SearchResultsPage.css";

const PAGE_SIZE = 5;

const SearchResultsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { keyword, pageNumber } = useParams();

  const { totalStories, stories, mostPopular, mostViewed } = useSelector(
    (state) => ({
      totalStories: state.headlines.searchResults.totalStories,
      stories: state.headlines.searchResults.stories,
      mostPopular: state.headlines.searchResults.mostPopular,
      mostViewed: state.headlines.searchResults.mostViewed,
    })
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (keyword === "unsubscribe") {
      history.push("/user/update-preferences");
    } else {
      getSearchResultsPage(pageNumber || 1);
    }
  }, [keyword]);

  const getSearchResultsPage = (pageNumber) => {
    console.log('pageNumber', pageNumber);

    setLoading(true);

    dispatch(
      headlineActions.getSearchResults({
        keyword,
        pageNumber: pageNumber,
        pageSize: PAGE_SIZE,
      })
    )
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        setLoading(false);
        window.scrollTo(0, 0);

        const path = `/search/${keyword}/${pageNumber}`;

        ReactGA.pageview(path);
        history.push(path);
      });
  };

  if (loading) {
    return <div id="se-pre-con" />;
  }

  if (!stories || stories.length === 0) {
    return null;
  }

  return (
    <div className="search-results fade-in">
      <section className="block-inner">
        <Container>
          <Row>
            <Col sm={12}>
              <h1>{`'${keyword}' Search Results: `}</h1>
              <h5>{`${totalStories} stories found`}</h5>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row>
          <Col sm={8}>
            {stories.map((story) => (
              <NewsBox
                story={story}
                orientation={"horizontal"}
                titleSize={"medium"}
              />
            ))}
          </Col>
          <Col sm={4}>
            <SearchBox />
            <GoogleAd showTitle adSlot={"4849226522"} maxHeight={"250px"} />
            <PopularViewedNews
              popularStories={mostPopular}
              viewedStories={mostViewed}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col sm={12}>
            <Pagination
              innerClass={"stories-pagination"}
              activePage={pageNumber}
              itemsCountPerPage={PAGE_SIZE}
              totalItemsCount={totalStories}
              pageRangeDisplayed={isMobile ? 3 : 5}
              onChange={getSearchResultsPage}
            />
          </Col>
          <Col sm={12}>
            <GoogleAd adSlot={"9047970765"} maxHeight={"90px"} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(SearchResultsPage);
