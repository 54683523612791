import * as types from '../actions/actionTypes';

const initialState = {
  suggestions: []
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_SEARCH_SUGGESTIONS: {
      const { data } = action.payload;

      return {
        ...state,
        suggestions: data
      }
    }

    case types.CLEAR_SEARCH_SUGGESTIONS: {
      return {
        ...state,
        suggestions: []
      }
    }
    
    default:
      return state;
  }
}