import React from 'react';
import { NavLink } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../../../assets/images/logo.png';

// import TopBannerAd from './TopBannerAd';
import GoogleAd from '../../shared/googleAds';

const TopBanner = () => {
  if (isMobile) {
    return <div className="top_banner_mobile" />
  }

  return (
    <div className="top_banner_wrap">
      <Container>
        <Row>
          <Col xs={12} md={4} sm={4}>
            <div className="header-logo">
              <NavLink to="/">
                <img className="td-retina-data img-fluid" src={logo} alt="logo" />
              </NavLink>
            </div>
          </Col>
          <Col xs={8} md={8} sm={8} className="d-none d-sm-block">
            <div className="header-banner">
              <GoogleAd
                minWidth={'400px'}
                maxWidth={'970px'}
                height={'90px'}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TopBanner;
