import * as types from '../actions/actionTypes';

const initialState = {
  userCounts: {
    visitors: [],
    openers: [],
    profiles: [],
    users: [],
    totalToSend: 0,
    maxUsers: 0,
    daysThresold: 0,
    projectedWarmupCounts: [],
  }
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_USER_COUNTS:
      const { data } = action.payload;

      return {
        ...state,
        userCounts: data
      }

    case types.SUBSCRIBE_USER:
    case types.SAVE_USER_VISIT:
    case types.UNSUBSCRIBE_USER:
    case types.UPDATE_USER_NEWSLETTER_FREQUENCY:
    default:
      return state;
  }
}