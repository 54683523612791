import React, { useEffect } from "react";

import "./GoogleAd.css";

const GoogleAd = (props) => {
  const {
    adSlot,
    width,
    height,
    showTitle,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
  } = props;

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  const renderManualResponsive = () => {
    const adStyle = {
      display: "inline-block",
      width: width || "100%",
      height: height || maxHeight,
    };

    if (minWidth) {
      adStyle.minWidth = minWidth;
    }

    if (maxWidth) {
      adStyle.maxWidth = maxWidth;
    }

    if (minHeight) {
      adStyle.minHeight = minHeight;
    }

    if (maxHeight) {
      adStyle.maxHeight = maxHeight;
    }

    return (
      <ins
        className="adsbygoogle"
        style={adStyle}
        data-ad-client="ca-pub-0295134909802085"
        data-ad-slot={adSlot}
      />
    );
  };

  const renderResponsive = (adSlot) => (
    <ins
      className="adsbygoogle"
      style={{ display: "inline-block" }}
      data-ad-client="ca-pub-0295134909802085"
      data-ad-slot={adSlot}
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  );

  const renderFixed = (adSlot, height, width) => (
    <ins
      className="adsbygoogle"
      style={{
        display: "inline-block",
        width,
        height,
      }}
      data-ad-client="ca-pub-0295134909802085"
      data-ad-slot={adSlot}
    />
  );

  const renderAd = () => {
    if (minWidth || minHeight || maxWidth || maxHeight) {
      return renderManualResponsive();
    }

    if (height && width) {
      return renderFixed(adSlot, height, width);
    }

    return renderResponsive(adSlot);
  };

  return (
    <div className="banner-add">
      {showTitle && <span className="add-title">- Advertisement -</span>}

      {renderAd()}
    </div>
  );
};

export default GoogleAd;