import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router";
import { Container, Row, Col } from "react-bootstrap";

import * as authActions from "../../redux/actions/authActions";

import "./LoginPage.css";

const LoginPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));

  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);

  const [loggingIn, setLoggingIn] = useState(false);

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLoginSubmit = () => {
    setLoggingIn(true);

    dispatch(authActions.login({ userName, password }))
      .then(() => {
        const { location } = props;

        if (location.state && location.state.redirect) {
          history.push(location.state.redirect);
        } else {
          history.push("/");
        }
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setLoggingIn(false);
      });
  };

  return (
    <div className="fade-in">
      <div className="login-page">
        <section className="block-inner">
          <Container>
            <Row>
              <Col sm={12}>
                <h1>Login & Registration</h1>
                <div className="breadcrumbs">
                  <ul>
                    <li>
                      <i className="pe-7s-home"></i>{" "}
                      <a href="/" title="">
                        Home
                      </a>
                    </li>
                    <li>Login & Registration</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="login-reg-inner">
          <Container>
            <Row>
              <Col sm={6}>
                <div className="login-form-inner">
                  <h3 className="category-headding ">LOGIN TO YOUR ACCOUNT</h3>
                  <div className="headding-border bg-color-1"></div>
                  <form>
                    <label>
                      Username Or Email <sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name_email"
                      name="name_email"
                      placeholder="Username or Email"
                      onChange={handleUserNameChange}
                    />
                    <label>
                      Password <sup>*</sup>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="pass"
                      name="pass"
                      onChange={handlePasswordChange}
                    />
                    <label className="checkbox-inline">
                      <input type="checkbox" value="" />
                      Remember me
                    </label>
                    <button
                      type="button"
                      className="btn btn-style"
                      onClick={handleLoginSubmit}
                    >
                      {loggingIn ? "Logging In..." : "Login"}
                    </button>
                    <div className="foeget">
                      <a href="#">Forget username/password?</a>
                    </div>
                  </form>
                </div>
              </Col>
              <Col sm={6}>
                <div className="register-form-inner">
                  <h3 className="category-headding ">
                    REGISTRATION COMING SOON!
                  </h3>
                  <div className="headding-border bg-color-1"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default withRouter(LoginPage);
