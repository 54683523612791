import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import './MakeUniqueModal.css';

const propTypes = {
  selectedText: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onTextChanged: PropTypes.func.isRequired,
  onMakeUnique: PropTypes.func.isRequired
}

const defaultProps = {
  selectedText: '',
  isOpen: false,
  onClose: () => { },
  onTextChanged: () => { },
  onMakeUnique: () => { }
}

const MakeUniqueModal = (props) => {
  const {
    isOpen,
    onClose,
    selectedText,
    onTextChanged,
    onMakeUnique
  } = props;

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '30px',
      marginRight: '-50%',
      maxWidth: '650px',
      transform: 'translate(-50%, -50%)'
    }
  };

  // if (isMobile) {
  //   customStyles.content.maxHeight = '85%';
  // }

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      appElement={document.getElementById('root')}
    >
      <div className="make-unique-modal-container">
        <div className="close-button" onClick={onClose}>X</div>
        <input
          type="text"
          value={selectedText}
          className="form-control"
          id="selectedText"
          name="selectedText"
          onChange={e => onTextChanged(e.target.value)}
          placeholder="Unique word or phrase"
          required
        />
        {/* {selectedText} */}
        <button
          type="button"
          className="btn btn-style"
          onClick={onMakeUnique}
        >
          Make Unique
        </button>
      </div>
    </ReactModal>
  )
}

MakeUniqueModal.propTypes = propTypes;
MakeUniqueModal.defaultProps = defaultProps;

export default MakeUniqueModal;