import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import * as headlineActions from "../../../redux/actions/headlineActions";
import {
  newsTypes,
  getCategoryName,
  getCategoryColorClass,
  getLinkUrl,
  renderHTML,
} from "../../../shared/utils";

import "./Header.css";

const TopHeader = (props) => {
  const { onDisplayModeChange } = props;

  const dispatch = useDispatch();

  const { auth, topNews, isAdmin } = useSelector((state) => ({
    auth: state.auth,
    topNews: state.headlines.topNews,
    isAdmin: state.auth.data && state.auth.data.role === "admin",
  }));

  const { heroes, others } = topNews;

  const allTopNews = heroes.concat(others);

  const [loading, setLoading] = useState(false);
  const [displayMode, setDisplayMode] = useState(
    isAdmin ? "admin" : "user"
  );
  const [showNewsletterDropdown, setShowNewsletterDropdown] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      headlineActions.getHeadlines({
        type: newsTypes.top,
        pageNumber: 1,
        pageSize: 7,
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.message);
      });
  }, []);

  const toggleDisplayMode = (e) => {
    e.preventDefault();

    if (displayMode === "admin") {
      setDisplayMode("user");
      onDisplayModeChange("user");
    } else {
      setDisplayMode("admin");
      onDisplayModeChange("admin");
    }
  };

  const toggleNewsletterDropdown = (e) => {
    e.preventDefault();

    setShowNewsletterDropdown(!showNewsletterDropdown);
  };

  return (
    <div className="top_header d-none d-sm-block">
      <Container>
        <Row>
          <Col sm={4} md={3}>
            <div className="top_header_menu_wrap">
              <ul className="top-header-menu">
                {!auth.token && (
                  <li>
                    <NavLink to="/login">LOGIN</NavLink>
                  </li>
                )}
                {!auth.token && (
                  <li>
                    <NavLink to="/privacy-policy">PRIVACY POLICY</NavLink>
                  </li>
                )}
                {auth.token && (
                  <li>
                    <a href="/" onClick={toggleDisplayMode}>
                      {displayMode === "user" ? "USER VIEW" : "ADMIN VIEW"}
                    </a>
                  </li>
                )}
                {auth.token && (
                  <li>
                    <a href="/?logout=1">LOGOUT</a>
                  </li>
                )}
                {isAdmin && (
                  <li
                    className={`dropdown${
                      showNewsletterDropdown ? " open" : ""
                    }`}
                  >
                    <a href="/" onClick={toggleNewsletterDropdown}>
                      NEWSLETTER
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <NavLink
                          onClick={() => setShowNewsletterDropdown(false)}
                          to="/admin/manageNewsletter"
                        >
                          Prepare
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setShowNewsletterDropdown(false)}
                          to="/admin/sendNewsletter"
                        >
                          Send
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setShowNewsletterDropdown(false)}
                          to="/admin/newsletterActivity"
                        >
                          View Activity
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => setShowNewsletterDropdown(false)}
                          to="/admin/newsletterDailyActivityGraph"
                        >
                          Activity Graph
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
            </div>
          </Col>
          <Col sm={8} md={7}>
            <div className="newsticker-inner owl-theme">
              <CarouselProvider
                className="newsticker"
                naturalSlideWidth={750}
                naturalSlideHeight={30}
                totalSlides={allTopNews.length}
                orientation={"vertical"}
                isPlaying
                infinite
              >
                <div className="owl-controls clickable">
                  <div className="owl-buttons">
                    <ButtonBack className="owl-prev">
                      <i className="pe-7s-angle-left" />
                    </ButtonBack>
                    <ButtonNext className="owl-next">
                      <i className="pe-7s-angle-right" />
                    </ButtonNext>
                  </div>
                </div>
                <Slider>
                  {allTopNews.map((story) => (
                    <Slide>
                      <span className={getCategoryColorClass(story.categoryId)}>
                        {getCategoryName(story.categoryId)}
                      </span>
                      <a href={getLinkUrl(story)}>{renderHTML(story.title)}</a>
                    </Slide>
                  ))}
                </Slider>
              </CarouselProvider>
            </div>
          </Col>
          <Col sm={12} md={2}>
            <div className="top_header_icon">
              <span className="top_header_icon_wrap">
                <a href="https://twitter.com/headlineintime" title="Twitter">
                  <i className="fa fa-twitter"></i>
                </a>
              </span>
              <span className="top_header_icon_wrap">
                <a href="https://www.facebook.com/bignewsbook" title="Facebook">
                  <i className="fa fa-facebook"></i>
                </a>
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TopHeader;
