import * as types from '../actions/actionTypes';

const initialState = [];

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CATEGORIES: {
      const { data } = action.payload;

      return [...data];
    }

    default:
      return state;
  }
}