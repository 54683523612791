import { getRequest, URL, postRequest, putRequest, deleteRequest, newsTypes } from '../../shared/utils';
import * as types from './actionTypes';

export function register(credentials, userToken = '') {
  const url = `${URL.auth}/register`;
  const request = postRequest(url, credentials, userToken);

  return {
    type: types.REGISTER,
    payload: request,
  };
}

export function login(credentials, userToken = '') {
  const url = `${URL.auth}/login`;
  const request = postRequest(url, credentials, userToken);

  return {
    type: types.LOGIN,
    payload: request,
  };
}

export function logout() {
  return {
    type: types.LOGOUT,
  };
}

export function resetPassword(credentials, userToken = '') {
  const url = `${URL.auth}/reset_password`;
  postRequest(url, credentials, userToken);
  return {
    type: types.RESET_PASSWORD,
  };
}

export function requestPasswordReset(email, userToken = '') {
  const url = `${URL.auth}/requestPasswordReset/${email}`;
  getRequest(url, null, userToken);
  return {
    type: types.REQUEST_PASSWORD_RESET,
  };
}