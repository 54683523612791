import React from 'react';
// import PropTypes from 'prop-types';

// const propTypes = {
//   stories: PropTypes.array
// }

// const defaultProps = {
//   stories: []
// }

const TopRated = () => {
  return (
    <div className="footer-box">
      <h3 className="category-headding">TOP RATED</h3>
      <div className="headding-border bg-color-3"></div>
      <table>
        <tbody>
          <tr>
            <td>
              <i className="fa fa-star"></i> <i className="fa fa-star"></i>
              <i className="fa fa-star"></i> <i className="fa fa-star"></i>
              <i className="fa fa-star-half-o"></i>
            </td>
            <td><a href="http://www.nyt.com">New York Times</a></td>
          </tr>
          <tr>
            <td>
              <i className="fa fa-star"></i> <i className="fa fa-star"></i>
              <i className="fa fa-star"></i> <i className="fa fa-star-half-o"></i>
              <i className="fa fa-star-o"></i>
            </td>
            <td><a href="http://www.bbc.com">BBC</a></td>
          </tr>
          <tr>
            <td>
              <i className="fa fa-star"></i> <i className="fa fa-star"></i>
              <i className="fa fa-star"></i> <i className="fa fa-star"></i>
              <i className="fa fa-star-half-o"></i>
            </td>
            <td><a href="http://www.cnn.com">CNN</a></td>
          </tr>
          <tr>
            <td>
              <i className="fa fa-star"></i> <i className="fa fa-star"></i>
              <i className="fa fa-star"></i> <i className="fa fa-star"></i>
              <i className="fa fa-star-half-o"></i>
            </td>
            <td><a href="http://www.foxnews.com">Fox News</a></td>
          </tr>
          <tr>
            <td>
              <i className="fa fa-star"></i> <i className="fa fa-star"></i>
              <i className="fa fa-star"></i> <i className="fa fa-star"></i>
              <i className="fa fa-star-half-o"></i>
            </td>
            <td><a href="http://www.tmz.com">TMZ</a></td>
          </tr>
          <tr>
            <td>
              <i className="fa fa-star"></i> <i className="fa fa-star"></i>
              <i className="fa fa-star"></i> <i className="fa fa-star"></i>
              <i className="fa fa-star-half-o"></i>
            </td>
            <td><a href="http://www.usatoday.com">USA Today</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

// FeaturedNews.propTypes = propTypes;
// FeaturedNews.defaultProps = defaultProps;

export default TopRated;