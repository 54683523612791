import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router";
import Sticky from "react-sticky-el";
import { HashLink as Link } from "react-router-hash-link";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, Row, Col } from "react-bootstrap";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Checkbox from "@material-ui/core/Checkbox";

import * as headlineActions from "../../../redux/actions/headlineActions";
import * as newsletterActions from "../../../redux/actions/newsletterActions";

import NewsBox from "../../shared/newsBox/NewsBox";

import PreviewModal from "./previewModal";

import "./ManageNewsletterPage.css";

const CANDIDATES_TAKE = 150;
const CATEGORY_SELECTED_ID = 99;
const MAX_NEWSLETTER_ARTICLES = 10;

function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const ManageNewsletterPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { auth, newsletter, sendScheduled, spamReport, newsletterCandidates } = useSelector(
    (state) => ({
      auth: state.auth,
      newsletter: state.newsletter.newsletter,
      sendScheduled: state.newsletter.sendScheduled,
      spamReport: state.newsletter.spamReport,
      newsletterCandidates: state.headlines.newsletterCandidates,
    })
  );

  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [subject, setSubject] = useState("");
  const [selectedStories, setSelectedStories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [starRatingSort, setStarRatingSort] = useState(true);
  const [excludeSites, setExcludeSites] = useState([
    { id: 12, name: "CNN", selected: false },
    { id: 2, name: "Huff Po", selected: true },
    { id: 3, name: "TMZ", selected: false },
    { id: 122, name: "Twitchy", selected: true },
    { id: 22, name: "NY Post", selected: false },
    { id: 31, name: "Breitbart", selected: false },
    { id: 129, name: "Daily Caller", selected: false },
    { id: 61, name: "The Blaze", selected: false },
  ]);

  const [selectedSort, setSelectedSort] = useState("byDefault");
  const [generatingSubject, setGeneratingSubject] = useState(false);

  useEffect(() => {
    getNewsletterCandidates();
    getNewsletter();
  }, [selectedCategoryId]);

  useEffect(() => {
    getNewsletterCandidates();
  }, [excludeSites, selectedSort]);

  useEffect(() => {
    console.log("newsletter", newsletter);

    if (!newsletter) return;

    const { subject, selectedStories } = newsletter;

    if (selectedStories.length > 0) {
      setSaved(true);
    }

    if (selectedStories.length === MAX_NEWSLETTER_ARTICLES) {
      setStarRatingSort(false);
    } else {
      setStarRatingSort(true);
    }

    setSubject(subject || `Subject Goes Here`);
    setSelectedStories(selectedStories);

    // setSendScheduled(sendScheduled);
  }, [newsletter]);

  const getNewsletterCandidates = () => {
    setLoading(true);

    const excludeSiteIds = excludeSites
      .filter((s) => s.selected)
      .map((s) => s.id);

    dispatch(
      headlineActions.getNewsletterCandidates(
        CANDIDATES_TAKE,
        selectedCategoryId,
        excludeSiteIds,
        selectedSort,
        auth.token
      )
    )
      .then(() => {
        if (newsletterCandidates.length > 0) {
          setStarRatingSort(false);
        }
      })
      .catch((err) => {
        console.log(err.message);

        history.push({
          pathname: "/login",
          state: { redirect: "/admin/manageNewsletter" },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getNewsletter = () => {
    setLoading(true);

    dispatch(newsletterActions.getNewsletter(auth.token))
      .catch((err) => {
        console.log(err.message);

        history.push({
          pathname: "/login",
          state: { redirect: "/admin/manageNewsletter" },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const autoGenerateNewsletter = () => {
    setLoading(true);

    dispatch(newsletterActions.getNewsletterAutoGenerated(auth.token))
      .then(() => {
        const { newsletter } = newsletter;
        const { subject, selectedStories } = newsletter;

        if (selectedStories.length > 0) {
          setSaved(true);
        }

        if (selectedStories.length === MAX_NEWSLETTER_ARTICLES) {
          setStarRatingSort(false);
        } else {
          setStarRatingSort(true);
        }

        setSubject(subject || `Subject Goes Here`);
        setSelectedStories(selectedStories);
      })
      .catch((err) => {
        console.log(err.message);

        history.push({
          pathname: "/login",
          state: { redirect: "/admin/manageNewsletter" },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSiteFilterChange = (event) => {
    const { target } = event;
    const { checked, value } = target;

    const id = Number(value);

    var excludeSite = excludeSites.find((s) => s.id === id);
    excludeSite.selected = checked;

    setExcludeSites([...excludeSites]);
  };

  const handleCategoryFilterChange = (event) => {
    const { target } = event;
    const { checked, value } = target;

    const id = Number(value);

    if (checked) {
      setSelectedCategoryId(id);
    } else {
      setSelectedCategoryId(0);
    }
  };

  const renderSiteFilters = () => {
    return (
      <div className="category-filter">
        <b>Exclude:</b>
        {excludeSites.map((c) => (
          <span>
            <Checkbox
              checked={c.selected}
              onChange={handleSiteFilterChange}
              value={c.id}
            />
            {c.name}
          </span>
        ))}
      </div>
    );
  };

  const handleSortSelectChange = (option) => {
    const { value } = option;

    setSelectedSort(value);
  };

  const renderSort = () => {
    return (
      <Dropdown
        className="sort-dropdown"
        options={[
          { value: "byDefault", label: "Default" },
          { value: "byPopular", label: "By Most Popular" },
        ]}
        onChange={handleSortSelectChange}
        value={selectedSort}
        placeholder="Sort By"
      />
    );
  };

  const handleRatingSortChange = (event) => {
    const { checked } = event.target;

    if (checked) {
      setStarRatingSort(true);
      sortByRating();
    } else {
      setStarRatingSort(false);
    }
  };

  const renderRatingSortControl = () => {
    return (
      <div className="star-rating-sort">
        <span>
          <Checkbox
            checked={starRatingSort}
            onChange={handleRatingSortChange}
          />
          Sort By Rating
        </span>
      </div>
    );
  };

  const toggleSelect = (story) => {
    if (story.selected) {
      var array = [...selectedStories]; // make a separate copy of the array
      var index = array
        .map(function (e) {
          return e.id;
        })
        .indexOf(story.id);
      if (index !== -1) {
        array.splice(index, 1);
        setSelectedStories(array);
      }
      story.selected = false;
    } else {
      if (!story.articleTitle) story.articleTitle = story.title;
      if (!story.articleDescription)
        story.articleDescription = story.description;

      setSelectedStories([...selectedStories, story]);
      // setSortByRating(true);

      story.selected = true;
    }
  };

  const generateSubject = () => {
    setGeneratingSubject(true);

    var articleTitles = selectedStories.map((s) => s.title);

    dispatch(newsletterActions.generateSubject(articleTitles, auth.token))
      .then((result) => {
        const { data } = result.payload;

        setSubject(data);
      })
      .finally(() => {
        setGeneratingSubject(false);
      });
  };

  const removeStory = (story) => {
    var array = [...selectedStories]; // make a separate copy of the array
    var index = array
      .map(function (e) {
        return e.id;
      })
      .indexOf(story.id);
    if (index !== -1) {
      array.splice(index, 1);
      setSelectedStories(array);
    }
    story.selected = false;
  };

  const clearAllSelected = () => {
    for (let i = 0; i < newsletterCandidates.length; i++) {
      newsletterCandidates[i].selected = false;
    }

    setSelectedStories([]);
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    background: "white",
    ...draggableStyle,
    left: "auto !important",
    top: "auto !important",
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setSelectedStories(reorder(selectedStories, source.index, destination.index));
    // setSortByRating(false);
  };

  const handleTextSelected = (story, selectedText) => {
    var array = [...selectedStories]; // make a separate copy of the array
    var index = array
      .map(function (e) {
        return e.id;
      })
      .indexOf(story.id);
    if (index !== -1) {
      if (array[index].keywords) {
        if (array[index].keywords.includes(selectedText)) {
          return;
        }

        array[index].keywords = `${array[index].keywords}, ${selectedText}`;
      } else {
        array[index].keywords = selectedText;
      }

      setSelectedStories(array);
    }
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleKeywordChange = (story, e) => {
    var array = [...selectedStories]; // make a separate copy of the array
    var index = array
      .map(function (e) {
        return e.id;
      })
      .indexOf(story.id);
    if (index !== -1) {
      array[index].keywords = e.target.value;
      setSelectedStories(array);
    }
  };

  const handleRatingChange = (story, newRating) => {
    story.starRating = newRating;

    var array = [...selectedStories]; // make a separate copy of the array

    if (!story.selected) {
      story.selected = true;

      array.push(story);
    } else {
      var index = array
        .map(function (e) {
          return e.id;
        })
        .indexOf(story.id);

      if (index !== -1) {
        array[index].starRating = newRating;
      }
    }

    if (starRatingSort) {
      array.sort((a, b) =>
        !a.starRating || a.starRating < b.starRating ? 1 : -1
      );
    }

    setSelectedStories(array);

    dispatch(
      headlineActions.updateStarRating(story.id, newRating, auth.token)
    );
  };

  const handleTitleChange = (story, newTitle) => {
    dispatch(headlineActions.updateTitle(story.id, newTitle, auth.token));

    story.title = newTitle;
  };

  const sortByRating = () => {
    var array = [...selectedStories]; // make a separate copy of the array

    array.sort((a, b) =>
      !a.starRating || a.starRating < b.starRating ? 1 : -1
    );

    setSelectedStories(array);
  };

  const saveNewsletter = (callback) => {
    const { id } = newsletter;

    if (!subject) {
      toast.error("Please fill in subject", {
        position: toast.POSITION.TOP_LEFT,
      });

      return;
    }

    if (!selectedStories || selectedStories.length === 0) {
      toast.error("Please select stories", {
        position: toast.POSITION.TOP_LEFT,
      });

      return;
    }

    const storiesToSave = selectedStories.slice(0, MAX_NEWSLETTER_ARTICLES);

    dispatch(
      newsletterActions.saveNewsletter(
        { id, subject, selectedStories: storiesToSave },
        auth.token
      )
    )
      .then(() => {
        toast.success("Newsletter Saved", {
          position: toast.POSITION.TOP_LEFT,
        });

        setSaved(true);

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        toast.error(`Error Saving Newsletter: ${err}`, {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  };

  const renderPreviewModal = () => {
    const { id } = newsletter;

    return (
      <PreviewModal
        newsletterId={id}
        isOpen={previewModalOpen}
        onClose={handlePreviewModalClose}
      />
    );
  };

  const handlePreviewModalClose = () => {
    setPreviewModalOpen(false);
  };

  const previewNewsletter = () => {
    saveNewsletter();

    setPreviewModalOpen(true);
  };

  const sendNewsletter = () => {
    history.push("/admin/sendNewsletter");
  };

  return (
    <div className="manage-newsletter">
      <ToastContainer />
      {renderPreviewModal()}
      <section className="block-inner">
        <Container>
          <Row>
            <Col sm={12}>
              <textarea
                className="subject"
                type="text"
                value={subject}
                cols={70}
                rows={2}
                onChange={handleSubjectChange}
                placeholder="Subject"
              />
              <br />
              <div className="btn-group">
                {selectedStories.length === 0 && (
                  <button
                    className="btn btn-style auto-gen"
                    onClick={autoGenerateNewsletter}
                  >
                    Generate
                  </button>
                )}
                {selectedStories.length > 0 && (
                  <button
                    className="btn btn-style"
                    onClick={() => generateSubject()}
                  >
                    {generatingSubject ? "Generating..." : "Generate Subject"}
                  </button>
                )}
                {selectedStories.length > 0 && (
                  <button
                    className="btn btn-style save"
                    onClick={() => saveNewsletter()}
                  >
                    Save
                  </button>
                )}
                <button
                  className="btn btn-style send"
                  onClick={previewNewsletter}
                >
                  Preview
                </button>
                {saved && (
                  <button
                    className="btn btn-style send"
                    onClick={sendNewsletter}
                    disabled={sendScheduled}
                  >
                    {sendScheduled ? "Send Scheduled" : "Send"}
                  </button>
                )}
              </div>
              {loading && (
                <div className="loading-message">
                  <h4>Loading...</h4>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row>
          <Col xs={12}>
            <div style={{ "margin-bottom": "10px" }}>
              <div style={{ display: "inline-block" }}>
                {renderSiteFilters()}
              </div>
              <div
                style={{
                  display: "inline-block",
                  position: "absolute",
                  right: 0,
                  width: "200px",
                }}
              >
                {renderSort()}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={7}>
            {!loading && newsletterCandidates.length === 0 && (
              <h4>No articles</h4>
            )}
            {newsletterCandidates.map((story) => {
              const selectedStory = selectedStories.find(
                (s) => s.id === story.id
              );

              if (selectedStory) {
                story.selected = true;
                story.keywords = selectedStory.keywords;
              } else {
                if (selectedCategoryId === CATEGORY_SELECTED_ID) return null;
              }

              return (
                <div className="newsletter-candidate-container">
                  <a id={`article_${story.id}`}>
                    <NewsBox
                      story={story}
                      orientation={"horizontal"}
                      titleSize={"medium"}
                      titleEditable={true}
                      onTitleChanged={(newTitle) =>
                        handleTitleChange(story, newTitle)
                      }
                      lazyLoadImage={false}
                      showCategoryBadge
                      showSiteLogo
                      showStarRating={true}
                      onRatingSelect={(newRating) =>
                        handleRatingChange(story, newRating)
                      }
                      showDescription
                      descriptionLines={50}
                      selectText={story.selected ? "Unselect" : "Select"}
                      onSelect={() => toggleSelect(story)}
                      isAdmin
                      showDeleteButton={false}
                    />
                  </a>
                </div>
              );
            })}
          </Col>
          <Col sm={2}>
            <Sticky>
              <h4>Jump To</h4>
              {selectedStories
                .slice(0, MAX_NEWSLETTER_ARTICLES)
                .map((story, index) => (
                  <div className="jump-text">
                    <Link smooth to={`#article_${story.id}`}>
                      {index + 1}. {decodeHtml(story.title).substring(0, 50)}...
                    </Link>
                    {/* <Link smooth to={`#article_${story.id}`}>[Story]</Link> */}
                    <br />
                    <button
                      onClick={() => {
                        removeStory(story);
                      }}
                    >
                      Remove
                    </button>
                  </div>
                ))}
            </Sticky>
          </Col>
          <Col sm={3}>
            <Sticky>
              <h4>Selected Stories</h4>
              {selectedStories.length === 0 && <h6>(None Selected)</h6>}
              {selectedStories.length > 0 && (
                <div>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          // style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {selectedStories
                            .slice(0, MAX_NEWSLETTER_ARTICLES)
                            .map((story, index) => (
                              <Draggable
                                key={`story-${story.id}`}
                                draggableId={`story-${story.id}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <NewsBox
                                      story={story}
                                      orientation={"horizontal"}
                                      titleSize={"small"}
                                      showCategoryBadge
                                      showSiteName
                                      useImageUrl
                                      lazyLoadImage={false}
                                      showDeleteButton={true}
                                      onDelete={() => removeStory(story)}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <button onClick={clearAllSelected}>Clear All</button>
                  {renderRatingSortControl()}
                </div>
              )}
            </Sticky>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(ManageNewsletterPage);
