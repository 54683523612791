import { getRequest, URL, postRequest, putRequest, deleteRequest, newsTypes } from '../../shared/utils';
import * as types from './actionTypes';

export function getSuggestions(value) {
  const url = `${URL.headline}/searchSuggestions?text=${value}`;
  var request = getRequest(url);

  return {
    type: types.GET_SEARCH_SUGGESTIONS,
    payload: request
  };
}

export function clearSuggestions() {
  return {
    type: types.CLEAR_SEARCH_SUGGESTIONS
  };
}