import { getRequest, URL, postRequest, putRequest, deleteRequest, newsTypes } from '../../shared/utils';
import * as types from './actionTypes';

export function getHomePageHeadlines() {
  const url = `${URL.headline}/getHomePageData`;

  const request = getRequest(url);

  return {
    type: types.GET_HOME_PAGE_NEWS,
    payload: request
  };
}

export function getCategoryPageHeadlines(data) {
  const url =
    `${URL.headline}/getCategoryPageData?categoryId=${data.categoryId}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;

  const request = getRequest(url, data);

  return {
    type: types.GET_CATEGORY_NEWS,
    payload: request
  };
}

export function getSearchResults(data) {
  const url =
    `${URL.headline}/search?keyword=${data.keyword}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;

  const request = getRequest(url, data);

  return {
    type: types.GET_SEARCH_RESULTS,
    payload: request
  };
}

export function getHeadline(storyUrl) {
  const url =
    `${URL.headline}/${storyUrl}`;

  const request = getRequest(url);

  return {
    type: types.GET_HEADLINE,
    payload: request
  };
}

export function getNewsletterCandidates(take, categoryId, excludeSiteIds, sort, userToken) {
  let url = `${URL.headline}/getNewsletterCandidates?categoryId=${categoryId}&take=${take}`;

  if (excludeSiteIds && excludeSiteIds.length > 0) {
    excludeSiteIds.forEach(s => {
      url += `&excludeSiteIds=${s}`;
    })
  }

  if (sort) {
    url += `&sort=${sort}`;
  }

  const request = getRequest(url, null, userToken);

  return {
    type: types.GET_NEWSLETTER_CANDIDATES,
    payload: request
  }
}

export function getHeadlines(data) {
  let url = `${URL.headline}?type=${data.type}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;

  if (data.categoryId) {
    url += `&categoryId=${data.categoryId}`;
  }

  const request = getRequest(url, data);

  switch (data.type) {
    case newsTypes.top:
      return {
        type: types.GET_TOP_NEWS,
        payload: request
      };
    case newsTypes.recent:
      return {
        type: types.GET_RECENT_NEWS,
        payload: request
      };
    case newsTypes.mostPopular:
      return {
        type: types.GET_MOST_POPULAR_NEWS,
        payload: request
      };
    case newsTypes.mostViewed:
      return {
        type: types.GET_MOST_VIEWED_NEWS,
        payload: request
      };
    case newsTypes.featured:
      return {
        type: types.GET_FEATURED_NEWS,
        payload: request
      };
    default:
      return {
        type: types.GET_CATEGORY_NEWS,
        payload: request
      };
  }
}

export function getHeadlineAiContent(title, description, userToken) {
  let url = `${URL.headline}/getAiContent`;
  
  const request = postRequest(url, {title, description}, userToken);

  return {
    type: types.GET_HEADLINE_AI_CONTENT,
    payload: request
  }
}

export function makeUnique(uniqueText, userToken) {
  const url = `${URL.headline}/makeUnique?uniqueText=${uniqueText}`;

  var request = postRequest(url, null, userToken);

  return {
    type: types.MAKE_UNIQUE,
    payload: request
  };
}

export function deleteStory(story, userToken) {
  const url = `${URL.headline}/${story.id}`;

  var request = deleteRequest(url, userToken);

  return {
    type: types.DELETE_ARTICLE,
    payload: request
  };
}

export function updateStarRating(id, starRating, userToken) {
  const url = `${URL.headline}/starRating?id=${id}&starRating=${starRating}`;

  var request = putRequest(url, null, userToken);

  return {
    type: types.UPDATE_STAR_RATING,
    payload: request
  };
}

export function updateTitle(id, title, userToken) {
  // TODO: Update title via API
  const url = `${URL.headline}/title?id=${id}&title=${title}`;

  var request = putRequest(url, null, userToken);

  return {
    type: types.UPDATE_TITLE,
    payload: request
    // payload: { id, title }
  };
}