import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PageNotFound = () => {
  return (
    <Container className="not-found">
      <Row>
        <Col xs={12}><h1>Oops! Page Not Found.</h1></Col>
      </Row>
    </Container>
  )
}

export default PageNotFound;
