import React, { useEffect, useState } from "react";
import GoogleAd from "../../googleAds";

import "./MobileBottomAd.css";

const MobileBottomAd = (props) => {
  const { adSlot } = props;

  return (
    <div className="mobile-bottom-ad-container">
      <div className="mobile-bottom-ad">
        {/* <button className="close-btn" onClick={() => setState({ adIsOpen: false })}>X</button> */}
        <GoogleAd
          adSlot={adSlot}
          maxHeight={"50px"}
          // maxWidth={'80%'}
        />
      </div>
    </div>
  );
};

export default MobileBottomAd;