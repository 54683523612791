import React from 'react';
import ReactModal from 'react-modal';
import Iframe from 'react-iframe';
import { URL } from '../../../../shared/utils';

import './PreviewModal.css';

function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const PreviewModal = (props) => {
  const {
    newsletterId,
    isOpen,
    onClose,
    report
  } = props;

  return (
    <ReactModal
      isOpen={isOpen}
      // style={customStyles}
      // className="preview-modal"
      // overlayClassName="preview-modal-overlay"
      appElement={document.getElementById('root')}
    >
      <div className="preview-modal-container">
        <div className="close-button" onClick={onClose}>X</div>
        {!newsletterId && <>Loading...</>}
        {newsletterId &&
          <Iframe
            className="iframe"
            url={`${URL.baseUrl}newsletter/view?newsletterId=${newsletterId}&email=test@test.com&getLatest=true`}
          />
        }
      </div>
      <div className="spam-report-container">
        <div style={{ "white-space": "pre" }} dangerouslySetInnerHTML={{ __html: decodeHtml(report) }} />
      </div>
    </ReactModal>
  )
}

export default PreviewModal;