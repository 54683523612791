import * as types from '../actions/actionTypes';

const initialState = {
  homePageNews: {},
  topNews: {
    heroes: [],
    others: []
  },
  categoryPageNews: {},
  searchResults: [],
  featuredNews: [],
  newsletterCandidates: [],
  spamReport: "",
  story: {
    article: {
      id: 0,
      title: "",
      body: "",
      category: "",
      date: "",
      imageUrl: "",
      source: "",
      sourceUrl: "",
      starRating: 0,
      tags: [],
      url: "",
    },
    relatedNews: [],
    mostFavorite: [],
    mostViewed: [],
    categories: []
  },
  headlineAiContent: {}
}

export default function headlineReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_HOME_PAGE_NEWS: {
      const { data } = action.payload;

      return {
        ...state,
        homePageNews: data
      }
    }

    case types.GET_TOP_NEWS: {
      const { data } = action.payload;
      const { stories } = data;

      return {
        ...state,
        topNews: {
          heroes: stories.slice(0, stories.length - 4),
          others: stories.slice(stories.length - 4, stories.length)
        }
      }
    }

    case types.GET_CATEGORY_NEWS: {
      const { data } = action.payload;

      return {
        ...state,
        categoryPageNews: data || {}
      }
    }

    case types.GET_SEARCH_RESULTS: {
      const { data } = action.payload;

      return {
        ...state,
        searchResults: data || []
      }
    }

    case types.GET_HEADLINE: {
      const { data } = action.payload;

      return {
        ...state,
        story: data || {}
      }
    }

    case types.GET_HEADLINE_AI_CONTENT: {      
      const { data } = action.payload;

      return {
        ...state,
        headlineAiContent: data || {}
      }
    }

    case types.GET_FEATURED_NEWS: {
      return {
        ...state,
        featuredNews: action.payload.data.stories
      }
    }

    case types.GET_NEWSLETTER_CANDIDATES: {
      const { data } = action.payload;

      return {
        ...state,
        newsletterCandidates: data
      }
    }

    case types.CLEAR_CATEGORY: {
      const { categoryId } = action;

      return {
        ...state,
        categoryNews: [
          ...state.categoryNews.filter(c => c.categoryId !== categoryId)
        ]
      }
    }

    case types.MAKE_UNIQUE:
      return state;

    case types.DELETE_ARTICLE:
      return state;

    case types.UPDATE_TITLE:
      return state;
    // const { id, title } = action.payload;

    // return {
    //   ...state,

    // }

    case types.UPDATE_STAR_RATING:
    default:
      return state;
  }
}