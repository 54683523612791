import * as types from '../actions/actionTypes';

const initialState = {
  newsletter: {
    id: 0,
    subject: '',
    selectedStories: []
  },
  newsletters: [],
  sendActivity: [],
  isps: [],
  ispActivity: [],
  dailyActivity: [],
  ispCounts: [],
  ispBoxPlacements: [],
  sendCounts: [],
  spamReport: ''
}

export default function newsletterReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_NEWSLETTER:
    case types.SAVE_NEWSLETTER: {
      const { data } = action.payload;

      console.log("getNewsletter", data);
      
      if (!data) {
        return state;
      }

      return {
        ...state,
        newsletter: data
      }
    }

    case types.GET_NEWSLETTERS: {
      const { data } = action.payload;

      if (!data) {
        return state;
      }

      return {
        ...state,
        newsletters: data
      }
    }

    case types.GET_NEWSLETTER_SEND_ACTIVITY: {
      const { data } = action.payload;

      if (!data) {
        return state;
      }

      return {
        ...state,
        sendActivity: data
      }
    }

    case types.GET_NEWSLETTER_FAILS: {
      const { data } = action.payload;

      if (!data) {
        return state;
      }

      return {
        ...state,
        fails: data
      }
    }

    case types.GET_NEWSLETTER_ISPS: {
      const { data } = action.payload;

      if (!data) {
        return state;
      }

      return {
        ...state,
        isps: data
      }
    }

    case types.GET_NEWSLETTER_ISP_ACTIVITY: {
      const { data } = action.payload;

      if (!data) {
        return state;
      }

      return {
        ...state,
        ispActivity: data
      }
    }

    case types.GET_NEWSLETTER_DAILY_ACTIVITY: {
      const { data } = action.payload;

      if (!data) {
        return state;
      }

      return {
        ...state,
        dailyActivity: data
      }
    }

    case types.GET_NEWSLETTER_ISP_COUNTS: {
      const { data } = action.payload;

      if (!data) {
        return state;
      }

      return {
        ...state,
        ispCounts: data
      }
    }

    case types.GET_NEWSLETTER_ISP_BOX_PLACEMENTS: {
      const { data } = action.payload;

      if (!data) {
        return state;
      }

      return {
        ...state,
        ispBoxPlacements: data
      }
    }

    case types.GET_NEWSLETTER_SEND_COUNTS: {
      const { data } = action.payload;

      if (!data) {
        return state;
      }

      return {
        ...state,
        sendCounts: data
      }
    }

    case types.GENERATE_CONTENT: {
      const { data } = action.payload;

      if (!data) {
        return state;
      }

      return {
        ...state,
        newsletter: {
          ...state.newsletter,
          subject: data.subject,
          selectedStories: [...data.articles]
        }
      }
    }

    case types.GET_NEWSLETTER_SPAM_REPORT: {
      const { data } = action.payload;

      if (!data) {
        return state;
      }

      return {
        ...state,
        spamReport: data
      }
    }

    case types.SAVE_NEWSLETTER_ARTICLE:
    case types.SAVE_NEWSLETTER_SEND_COUNTS:
    case types.SEND_NEWSLETTER:
    case types.SCHEDULE_NEWSLETTER:
    case types.SEND_TEST_NEWSLETTER:
    default:
      return state;
  }
}