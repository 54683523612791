import React, { useState } from "react";
import ReactModal from "react-modal";
import DataGrid from "react-data-grid";

import "./NewsletterFailsModal.css";

const defaultColumnProperties = {
  sortable: true,
  // width: 95
};

const MessageFormatter = (value) => {
  return (
    <a
      href="javascript:void(0)"
      onClick={() => {
        navigator.clipboard.writeText(value);
      }}
    >
      {value}
    </a>
  );
};

const columns = [
  {
    key: "ipAddress",
    name: "IP Address",
    width: 120,
  },
  {
    key: "emailAddress",
    name: "Email",
    width: 200,
  },
  {
    key: "returnCode",
    name: "Code",
    width: 50,
  },
  {
    key: "serverResponse",
    name: "Failure Message",
    formatter({ row, column }) {
      return MessageFormatter(row[column.key]);
    },
  },
].map((c) => ({ ...c, ...defaultColumnProperties }));

const customStyles = {
  content: {
    top: "10%",
    left: "10%",
    right: "10%",
    bottom: "10%",
    // padding: '30px',
    // marginRight: '-50%',
    width: "100%",
    // transform: 'translate(-50%, -50%)'
  },
};

const NewsletterFailsModal = (props) => {
  const { fails = [], isOpen = false, onClose = () => {} } = props;

  const [currentSort, setCurrentSort] = useState({
    sortColumn: "isp",
    sortDirection: "ASC",
  });

  const [sortColumns, setSortColumns] = useState([]);
  const [activityRows, setActivityRows] = useState([...fails]);

  const rowGetter = (i) => {
    let row = { ...fails[i] };

    return row;
  };

  const sortRows = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };

    let sortedRows =
      sortDirection === "NONE"
        ? [...props.fails]
        : [...props.fails].sort(comparer);

    setActivityRows([...sortedRows]);
    setCurrentSort({ sortColumn, sortDirection });
  };

  const getComparator = (sortColumn) => {
    switch (sortColumn) {
      case "ipAddress":
      case "emailAddress":
      case "serverResponse":
        return (a, b) => a[sortColumn].localeCompare(b[sortColumn]);
      case "returnCode":
        return (a, b) => a[sortColumn] - b[sortColumn];
      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  };

  const sortedRows = () => {
    if (sortColumns.length === 0) return fails;

    const sortedRows = [...fails];

    sortedRows.sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === "ASC" ? compResult : -compResult;
        }
      }

      return 0;
    });

    return sortedRows;
  };

  const handleSortColumnChange = (columns) => {
    setSortColumns([...columns]);
  };

  return (
    <ReactModal
      className="fails-modal-container"
      isOpen={isOpen}
      appElement={document.getElementById("root")}
    >
      <div className="close-button" onClick={onClose}>
        X
      </div>
      <DataGrid
        columns={columns}
        rows={sortedRows()}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        // onRowsChange={handleRowChanged}
        sortColumns={sortColumns}
        onSortColumnsChange={handleSortColumnChange}
        className="fails-grid"
      />
    </ReactModal>
  );
};

export default NewsletterFailsModal;
