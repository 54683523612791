import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import NewsBox from '../../../shared/newsBox/NewsBox';

import '../RecentNews.css';

const HeroCarousel = (props) => {
    const { heroStories = [], onCommentClick, onDeleteClick, isAdmin } = props;

    const renderSlides = () => {
      return (
        heroStories.map(story => {
          return (
            <Slide>
              <div className="item">
                <NewsBox
                  story={story}
                  showDescription
                  descriptionLines={2}
                  showCategoryBadge
                  onCommentClick={onCommentClick}
                  onDelete={onDeleteClick}
                  showGradientOverlay
                  isAdmin={isAdmin}
                  lazyLoadImage={!isAdmin}
                />
              </div>
            </Slide>
          )
        })
      )
    }

    return (
      <CarouselProvider
        naturalSlideWidth={750}
        naturalSlideHeight={800}
        totalSlides={heroStories.length}
        visibleSlides={2}
        infinite
        touchEnabled={false}
        dragEnabled={!isAdmin}
      >
        <div className="owl-controls clickable">
          <div className="owl-buttons">
            <ButtonBack className="owl-prev">
              <i className="pe-7s-angle-left" />
            </ButtonBack>
            <ButtonNext className="owl-next">
              <i className="pe-7s-angle-right" />
            </ButtonNext>
          </div>
        </div>
        <Slider>
          {renderSlides(heroStories)}
        </Slider>
      </CarouselProvider>
    );
  }

export default HeroCarousel;