import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useParams } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from "react-ga";
import Sticky from "react-sticky-el";

import GoogleAd from "../shared/googleAds";
import PopularViewedNews from "../shared/popularViewedNews";
import NewsBox from "../shared/newsBox/NewsBox";
import SearchBox from "../search/searchBox";

import * as headlineActions from "../../redux/actions/headlineActions";

import "./CategoryPage.css";

import { getCategoryName, getCategoryId } from "../../shared/utils";

const PAGE_SIZE = 15;

const CategoryPage = () => {
  const dispatch = useDispatch();

  const { categoryUrl, pageNumber } = useParams();
  const categoryId = getCategoryId(categoryUrl);

  const [categoryName, setCategoryName] = useState("");

  const { mostPopular, mostViewed, categoryNews } = useSelector((state) => ({
    mostPopular: state.headlines.categoryPageNews.mostPopular,
    mostViewed: state.headlines.categoryPageNews.mostViewed,
    categoryNews: state.headlines.categoryPageNews.categoryNews,
    totalStories: state.headlines.categoryPageNews.totalStories,
  }));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageToFetch, setPageToFetch] = useState(1);
  const [fetchedNews, setFetchedNews] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchMoreData();
  }, []);

  useEffect(() => {
    if (categoryId) {
      setCategoryName(getCategoryName(categoryId));

      getCategoryPage(categoryId, pageNumber ? pageNumber : 1);

      setFetchedNews([]);
      setPageToFetch(1);
      setHasMore(true);

      window.scrollTo(0, 0);
    }
  }, [categoryUrl]);

  const trackPageView = () => {
    let path = `/category/${categoryUrl}`;

    if (pageNumber) {
      path += `/${pageNumber}`;
    }

    ReactGA.pageview(path);
  };

  const getCategoryPage = (categoryId, pageNumber) => {
    setLoading(true);

    var element = document.getElementById("se-pre-con");

    if (element && element.style) {
      element.style.width = null;
      element.style.height = null;
    }

    trackPageView();

    dispatch(
      headlineActions.getCategoryPageHeadlines({
        categoryId,
        pageNumber,
        pageSize: PAGE_SIZE,
      })
    )
      .catch((error) => {
        setError(true);
        console.log(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (categoryNews && categoryNews.length > 0) {
      setFetchedNews([...fetchedNews, ...categoryNews]);
      setPageToFetch(pageToFetch + 1);
    }
  }, [categoryNews]);

  const fetchMoreData = () => {
    dispatch(
      headlineActions.getCategoryPageHeadlines({
        categoryId,
        pageNumber: pageToFetch,
        pageSize: PAGE_SIZE,
      })
    )
      .catch((error) => {
        setError(true);
        console.log(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getHeroOtherStories = (fetchedNews) => {
    let heroStories = [];
    let otherStories = [];

    for (let i = 0; i < fetchedNews.length; i += PAGE_SIZE) {
      const slicedHeroes = fetchedNews.slice(i, i + 6);
      const slicedOthers = fetchedNews.slice(i + 7, i + 15);

      heroStories = [...heroStories, ...slicedHeroes];
      otherStories = [...otherStories, ...slicedOthers];
    }

    return { heroStories, otherStories };
  };

  if (loading) {
    return <div id="se-pre-con"></div>;
  }

  if (error) {
    return (
      <div className="fade-in">
        <div className="category-page">
          <Container>
            <Row>
              <Col sm={12}>
                <div className="error-message">
                  <h1>
                    Sorry, something went wrong on our end. Please try again in
                    a few moments.
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  const { heroStories, otherStories } = getHeroOtherStories(fetchedNews);

  return (
    <div className="fade-in">
      <div className="category-page">
        <section className="block-inner">
          <Container>
            <Row>
              <Col sm={12}>
                <h1>{categoryName}</h1>
                <div className="breadcrumbs">
                  <ul>
                    <li>
                      <i className="pe-7s-home"></i>{" "}
                      <a href="/" title="">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="#" title="">
                        {categoryName}
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Container>
          <Row>
            <Col sm={5}>
              <InfiniteScroll
                dataLength={heroStories.length}
                // next={fetchMoreData}
                hasMore={hasMore}
              >
                {heroStories.map((hero) => (
                  <div className="post-style1">
                    <NewsBox
                      story={hero}
                      isHero
                      titlePosition={"below"}
                      titleSize={"large"}
                      showDescription
                      showSiteLogo
                      showGradientOverlay
                    />
                  </div>
                ))}
              </InfiniteScroll>
            </Col>
            <Col sm={3}>
              <InfiniteScroll
                dataLength={otherStories.length}
                next={fetchMoreData}
                hasMore={hasMore}
                scrollThreshold={isMobile ? "5000px" : "80%"}
              >
                {otherStories.map((story) => (
                  <div className="post-style1">
                    <NewsBox
                      story={story}
                      titlePosition={"below"}
                      titleSize={"medium"}
                      showDescription
                      showSiteLogo
                      showGradientOverlay
                    />
                  </div>
                ))}
              </InfiniteScroll>
            </Col>
            {/* </InfiniteScroll> */}

            <Col sm={4} className="left-padding right-rail">
              <SearchBox />
              <GoogleAd showTitle adSlot={"6180557867"} maxHeight={"250px"} />
              <PopularViewedNews
                popularStories={mostPopular}
                viewedStories={mostViewed}
              />
              {!isMobile && (
                <Sticky
                  topOffset={-50}
                  boundaryElement={".right-rail"}
                  hideOnBoundaryHit={false}
                >
                  <div className="sticky-google-ad-right">
                    <GoogleAd adSlot={"5507793968"} minHeight={"600px"} />
                  </div>
                </Sticky>
              )}
              {isMobile && (
                <GoogleAd adSlot={"5507793968"} minHeight={"150px"} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default withRouter(CategoryPage);
