import { combineReducers } from 'redux';
import headlines from './headlineReducer';
import users from './userReducer';
import categories from './categoryReducer';
import search from './searchReducer';
import sites from './siteReducer';
import newsletter from './newsletterReducer';
import auth from './authReducer';

const rootReducer = combineReducers({
  headlines,
  users,
  categories,
  search,
  sites,
  newsletter,
  auth
});

export default rootReducer;
