import React from 'react';
import { NavLink } from 'react-router-dom';
import { categories } from '../../utils';
import { URL } from '../../../shared/utils';

export const getCategoryName = (categoryId) => (
  categories.find(c => c.id === categoryId).name
)

export const getCategoryId = (url) => (
  categories.find(c => c.url === url).id
)

export const getCategoryClassName = (categoryId) => (
  categories.find(c => c.id === categoryId).name || ''
)

export const getCategoryUrl = (categoryId) => (
  categories.find(c => c.id === categoryId).url
)

export const getCategoryColorClass = (categoryId) => (
  `color-${categories.find(c => c.id === categoryId).colorCode}`
)

export const getCategoryBgColorClass = (categoryId) => (
  `bg-color-${categories.find(c => c.id === categoryId).colorCode}`
)

export const getImageUrl = (url) => (
  //`https://headlineintime-v3.azurewebsites.net/image/full/${id}`
  `${URL.baseUrl}image/full/${url}`
  // `https://headlineintime-service-aspnetcore.azurewebsites.net/api/image/full/${url}`
)

export const nFormatter = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }

  return num;
};


export function formatDate(string) {
  var options = { year: 'numeric', month: 'short', day: 'numeric' };

  var date = new Date(string);
  // var date = convertUTCDateToLocalDate(new Date(string));
  // console.log(date);

  return date.toLocaleDateString([], options);
}

export function getLinkUrl(story) {
  return `${window.location.origin}/story/${story.urlFriendlyName}`;
}

export const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

export const renderBadge = (categoryId) => {
  var category = categories.find(c => c.id === categoryId);

  return (
    <NavLink className={`post-badge ${category.badgeClass}`} to={`/category/${category.url}`}>
      {category.badgeText}
    </NavLink>
  )
}
