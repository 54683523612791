import React, { useEffect } from "react";
import uuid from "uuid";

import "./RevContentAds.css";

const RevContentAd = (props) => {
  const { widgetId } = props;

  let wrapperRef = null;

  useEffect(() => {
    if (wrapperRef !== null) {
      const widgetEl = document.createElement("div");
      widgetEl.setAttribute("id", `rc-widget-${uuid()}`);
      widgetEl.setAttribute("data-widget-id", widgetId);
      widgetEl.setAttribute("data-rc-widget", "");
      widgetEl.setAttribute("data-endpoint", "trends.revcontent.com");
      wrapperRef.appendChild(widgetEl);

      const script = document.createElement("script");
      script.src = "https://assets.revcontent.com/master/delivery.js";
      script.defer = true;
      wrapperRef.appendChild(script);
    }
  }, []);

  return (
    <div className="revcontent-ad">
      {/* <h3 className="category-headding">{title}</h3>
        {showAdsBy && <div className="ads-by-revcontent">Ads By RevContent</div>}
        <div className="headding-border"></div> */}
      <div ref={(ref) => (wrapperRef = ref)}></div>
    </div>
  );
};

export default RevContentAd;