import React from "react";
import { isMobile } from "react-device-detect";

import { CarouselProvider, Slider, Slide, Dot } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import NewsBox from "../../../shared/newsBox/NewsBox";
import "../TopNews.css";

const renderNavButtons = (heroStories) => {
  return heroStories.map((story, i) => {
    return (
      <div className="owl-page">
        <Dot className="carousel-nav-button" slide={i} />
      </div>
    );
  });
};

const HeroCarousel = (props) => {
  const { heroStories, onCommentClick, isAdmin } = props;

  const renderSlides = () => {
    return heroStories.map((story) => {
      return (
        <Slide>
          <div className="item">
            <NewsBox
              story={story}
              titlePosition={"inside"}
              titleSize={"large"}
              zoomOnHover
              onCommentClick={onCommentClick}
              showGradientOverlay
              useImageUrl
              showDeleteButton={false}
              lazyLoadImage={false}
              isAdmin={isAdmin}
            />
          </div>
        </Slide>
      );
    });
  };

  return (
    <CarouselProvider
      naturalSlideWidth={750}
      naturalSlideHeight={isMobile ? 550 : 600}
      totalSlides={heroStories.length}
      isPlaying={!isAdmin}
      infinite
      dragEnabled={!isAdmin}
    >
      <div className="owl-controls clickable">
        <div className="owl-pagination">{renderNavButtons(heroStories)}</div>
      </div>
      <Slider>{renderSlides(heroStories)}</Slider>
    </CarouselProvider>
  );
};

export default HeroCarousel;
