import React from 'react';
import ScrollToTop from 'react-scroll-up';

const Scroller = () => {
  // const activeStyle = { color: "#F15B2A" };

  return (
    <ScrollToTop
      showUnder={5}
      duration={1000}
    >
      <div id="toTop" className="btn btn-info">
        <span className="fa fa-dot-circle-o top" />
        <div>TOP</div>
      </div>
    </ScrollToTop>
  )
}

export default Scroller;