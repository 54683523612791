import React from 'react';
import PropTypes from 'prop-types';

import NewsBox from '../../../shared/newsBox/NewsBox';
import './FeaturedNews.css';

const propTypes = {
  stories: PropTypes.array,
  onCommentClick: PropTypes.func
}

const defaultProps = {
  stories: [],
  onCommentClick: () => { }
}

const FeaturedNews = (props) => {
  const { stories, onCommentClick } = props;

  return (
    <div className="footer-box featured-news">
      <h3 className="category-headding ">FEATURED NEWS</h3>
      <div className="headding-border bg-color-2"></div>
      {stories.map(story => (
        <NewsBox
          story={story}
          orientation={'horizontal'}
          titleSize={'small'}
          showCategoryBadge
          onCommentClick={onCommentClick}
          showSiteLogo={false}
        // showSiteName
        />
      ))}
    </div>
  )
}

FeaturedNews.propTypes = propTypes;
FeaturedNews.defaultProps = defaultProps;

export default FeaturedNews;