import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useHistory, useParams } from "react-router";
import Autosuggest from "react-autosuggest";

import * as searchActions from "../../../redux/actions/searchActions";

import "./SearchBox.css";

const getSuggestionValue = (suggestion) => suggestion;

const renderSuggestion = (suggestion) => <span>{suggestion}</span>;

const SearchBox = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { suggestions } = useSelector((state) => ({
    suggestions: state.search.suggestions,
  }));

  const [value, setValue] = useState("");

  const {
    placeholder = "Search articles here ...",
    onSearch = () => {},
    isMobile = false,
  } = props;

  useEffect(() => {
    dispatch(searchActions.getSuggestions(value));
  }, [value]);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    dispatch(searchActions.getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    dispatch(searchActions.clearSuggestions());
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    onSearch();

    history.push(`/search/${suggestion}`);
    setValue("");

    dispatch(searchActions.clearSuggestions());
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  const onSubmit = () => {
    onSearch();

    history.push(`/search/${value}`);
  };

  return (
    <div
      className={`search-box autosuggest input-group ${
        isMobile ? "custom-search-form" : "search-area"
      }`}
    >
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          placeholder,
          value,
          onChange: onChange,
          onKeyDown: onKeyDown,
          className: "form-control",
        }}
      />
      <div className="input-group-btn">
        <button
          className={`btn ${isMobile ? "mobile-menu-btn" : "btn-search"}`}
          type={isMobile ? "button" : "submit"}
          onClick={onSubmit}
        >
          <i className="fa fa-search" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default withRouter(SearchBox);
