import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";

import footerLogo from "../../../assets/images/footer-logo.png";

import * as headlineActions from "../../../redux/actions/headlineActions";
import { newsTypes } from "../../../shared/utils";

import SubscribeForm from "./subscribeForm";
import FeaturedNews from "./featuredNews";
import TopRated from "./topRated";

const Footer = (props) => {
  const { featuredNews } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    dispatch(
      headlineActions.getHeadlines({
        type: newsTypes.featured,
        categoryId: null,
        pageNumber: 1,
        pageSize: 3,
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.message);
      });
  }, []);

  return (
    <div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="footer-box">
                <SubscribeForm />
              </div>
            </div>
            <div className="col-sm-4">
              <FeaturedNews stories={featuredNews} />
            </div>
            <div className="col-sm-4">
              <TopRated />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-3">
              <div className="footer-box footer-logo-address">
                <img src={footerLogo} className="img-fluid" alt="" />
                <address>
                  3609 Austin Bluffs Pkwy
                  <br />
                  Ste 31 - 1020
                  <br />
                  Colorado Springs, CO 80918-6658
                  <br />
                  USA
                  <br />
                  <NavLink to="/user/update-preferences">Unsubscribe</NavLink>
                </address>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="footer-box">
                <h3 className="category-headding">categories</h3>
                <div className="headding-border bg-color-4"></div>
                <ul>
                  <li>
                    <i className="fa fa-dot-circle-o"></i>
                    <NavLink to="/category/us-news">
                      US NEWS
                    </NavLink>
                  </li>
                  <li>
                    <i className="fa fa-dot-circle-o"></i>
                    <NavLink
                      to="/category/entertainment"
                    >
                      ENTERTAINMENT
                    </NavLink>
                  </li>
                  <li>
                    <i className="fa fa-dot-circle-o"></i>
                    <NavLink to="/category/tabloid">
                      TABLOID
                    </NavLink>
                  </li>
                  <li>
                    <i className="fa fa-dot-circle-o"></i>
                    <NavLink to="/category/politics">
                      POLITICS
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="footer-box">
                <h3 className="category-headding">POPULAR CATEGORY</h3>
                <div className="headding-border bg-color-5"></div>
                <ul>
                  <li>
                    <i className="fa fa-dot-circle-o"></i>
                    <NavLink to="/category/business">
                      BUSINESS
                    </NavLink>
                  </li>
                  <li>
                    <i className="fa fa-dot-circle-o"></i>
                    <NavLink to="/category/sports">
                      SPORTS
                    </NavLink>
                  </li>
                  <li>
                    <i className="fa fa-dot-circle-o"></i>
                    <NavLink
                      to="/category/technology"
                    >
                      TECHNOLOGY
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="footer-box">
                <h3 className="category-headding ">POPULAR CATEGORY</h3>
                <div className="headding-border"></div>
                <ul>
                  <li>
                    <i className="fa fa-dot-circle-o"></i>
                    <NavLink
                      to="/category/alternative"
                    >
                      ALTERNATIVE
                    </NavLink>
                  </li>
                  <li>
                    <i className="fa fa-dot-circle-o"></i>
                    <NavLink
                      to="/category/us-newspapers"
                    >
                      US NEWSPAPERS
                    </NavLink>
                  </li>
                  <li>
                    <i className="fa fa-dot-circle-o"></i>
                    <NavLink
                      to="/category/international"
                    >
                      INTERNATIONAL
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="sub-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <p>All Rights Reserved 2024</p>
              <div className="social">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/bignewsbook"
                      className="facebook"
                    >
                      <i className="fa fa-facebook"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/headlineintime"
                      className="twitter"
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
