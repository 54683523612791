import { getRequest, URL, postRequest, putRequest, deleteRequest, newsTypes } from '../../shared/utils';
import * as types from './actionTypes';

export function getCategories() {
  const url = URL.category;

  var request = getRequest(url);

  return {
    type: types.GET_CATEGORIES,
    payload: request
  };
}

export function clearCategory(categoryId) {
  return {
    type: types.CLEAR_CATEGORY,
    categoryId
  }
}
