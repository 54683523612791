import React from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-sticky-el';
import MobileMenu from './MobileMenu';
import TopHeader from './TopHeader';
import TopBanner from './TopBanner';
import Navbar from './Navbar';

const propTypes = {
  onDisplayModeChange: PropTypes.func,
}

const defaultProps = {
  onDisplayModeChange: () => { },
}

const Header = (props) => {
  // const activeStyle = { color: "#F15B2A" };
  const { onDisplayModeChange } = props;

  return (
    <header>
      <MobileMenu />
      <TopHeader onDisplayModeChange={onDisplayModeChange} />
      <TopBanner />
      <Sticky
        style={{ 'z-index': '1000', 'background': 'white' }}
      >
        <Navbar />
      </Sticky>
    </header>
  )
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;