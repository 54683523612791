import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import NewsBox from '../newsBox/NewsBox';
import './PopularViewedNews.css';

const propTypes = {
  popularStories: PropTypes.array,
  viewedStories: PropTypes.array,
  onCommentClick: PropTypes.func,
  showDeleteButton: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  isAdmin: PropTypes.bool
}

const defaultProps = {
  popularStories: [],
  viewedStories: [],
  onCommentClick: () => { },
  showDeleteButton: false,
  onDeleteClick: () => { },
  isAdmin: false
}

const PopularViewedNews = (props) => {
  const {
    popularStories,
    viewedStories,
    onCommentClick,
    showDeleteButton,
    onDeleteClick,
    isAdmin
  } = props;

  return (
    <div className="popular-viewed-news tab-inner">
      <Tabs className="tabs">
        <TabList>
          <Tab>
            POPULAR
      </Tab>
          <Tab>
            MOST VIEWED
      </Tab>
        </TabList>
        <TabPanel className="tab-content">
          <div className="tab-item-inner">
            {popularStories.map(story => (
              <NewsBox
                story={story}
                orientation={'horizontal'}
                titleSize={'small'}
                showCategoryBadge
                onCommentClick={onCommentClick}
                showDeleteButton={showDeleteButton}
                onDelete={onDeleteClick}
                isAdmin={isAdmin}
                lazyLoadImage={!isAdmin}
              // showSiteName
              />
            ))}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="tab-item-inner">
            {viewedStories.map(story => (
              <NewsBox
                story={story}
                orientation={'horizontal'}
                titleSize={'small'}
                showCategoryBadge
                onCommentClick={onCommentClick}
                showDeleteButton={showDeleteButton}
                onDelete={onDeleteClick}
                isAdmin={isAdmin}
                lazyLoadImage={!isAdmin}
              // showSiteName
              />
            ))}
          </div>
        </TabPanel>
      </Tabs>
    </div>
  )
}

PopularViewedNews.propTypes = propTypes;
PopularViewedNews.defaultProps = defaultProps;

export default PopularViewedNews;