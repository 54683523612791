export default [
  {
    id: 1,
    name: 'US Newspapers',
    url: 'us-newspapers',
    colorCode: '4',
    badgeText: 'U',
    badgeClass: 'btn_one',
  },
  {
    id: 2,
    name: 'Sports',
    url: 'sports',
    colorCode: '1',
    badgeText: 'S',
    badgeClass: 'btn_three'
  },
  {
    id: 4,
    name: 'Entertainment',
    url: 'entertainment',
    colorCode: '3',
    badgeText: 'E',
    badgeClass: 'btn_eight',
  },
  {
    id: 6,
    name: 'Technology',
    url: 'technology',
    colorCode: '2',
    badgeText: 'T',
    badgeClass: 'btn_six'
  },
  {
    id: 7,
    name: 'Politics',
    url: 'politics',
    className: 'politics',
    colorCode: '1',
    badgeText: 'P',
    badgeClass: 'btn_nine'
  },
  {
    id: 9,
    name: 'Business',
    url: 'business',
    className: 'buisness',
    colorCode: '5',
    badgeText: 'B',
    badgeClass: 'btn_five'
  },
  {
    id: 10,
    name: 'US News',
    url: 'us-news',
    colorCode: '1',
    badgeText: 'U',
    badgeClass: 'btn_two'
  },
  {
    id: 11,
    name: 'Alternative',
    url: 'alternative',
    colorCode: '6',
    badgeText: 'A',
    badgeClass: 'btn_four'
  },
  {
    id: 12,
    name: 'International',
    url: 'international',
    className: 'international',
    colorCode: '3',
    badgeText: 'I',
    badgeClass: 'btn_sev'
  },
  {
    id: 13,
    name: 'Popular'
  },
  {
    id: 14,
    name: 'Commented'
  },
  {
    id: 15,
    name: 'All News'
  },
  {
    id: 16,
    name: 'Tabloid',
    url: 'tabloid',
    colorCode: '2',
    badgeText: 'T',
    badgeClass: 'btn_ten'
  },
  {
    id: 17,
    name: 'Trending'
  }
]