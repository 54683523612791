import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter, useHistory, useParams } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { decode } from "js-base64";
import unsubscribeImg from "../../assets/images/Jim-Carrey-Smiles-Runs-Out-of-Court-Room.gif";
import * as userActions from "../../redux/actions/userActions";

import "./UnsubscribePage.css";

const isValidEmail = (email) => {
  let lastAtPos = email.lastIndexOf("@");
  let lastDotPos = email.lastIndexOf(".");

  if (
    !(
      lastAtPos < lastDotPos &&
      lastAtPos > 0 &&
      email.indexOf("@@") === -1 &&
      lastDotPos > 2 &&
      email.length - lastDotPos > 2
    )
  ) {
    return false;
  }

  return true;
};

const UnsubscribePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { email } = useParams();

  const [error, setError] = useState(false);
  const [isUnsubscribing, setIsUnsubscribing] = useState(false);
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);

  useEffect(() => {
    if (!isValidEmail(email)) {
      try {
        const decodedEmail = decode(email);

        if (!isValidEmail(decodedEmail)) {
          history.push("/user/update-preferences");

          return;
        }
      } catch (e) {
        history.push("/user/update-preferences");

        return;
      }
    }

    setIsUnsubscribing(true);

    dispatch(userActions.unSubscribeUser(email))
      .then(() => {
        setIsUnsubscribed(true);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsUnsubscribing(false);
        window.scrollTo(0, 0);
      });
  }, [email]);

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <div className="unsubscribe-page submitted">
            {isUnsubscribing && <h3>Unsubscribing...</h3>}
            {error && (
              <h3>There was a problem on our end, please try again later.</h3>
            )}
            {isUnsubscribed && (
              <div>
                <h3>You Are Now Unsubscribed.</h3>
                <h5>Bye!</h5>
                <img src={unsubscribeImg} alt="animated" />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(UnsubscribePage);
