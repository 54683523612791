import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useHistory, useParams } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { decode } from "js-base64";
import * as userActions from "../../redux/actions/userActions";

import "./NewsletterConfirmPage.css";

const isValidEmail = (email) => {
  if (email.endsWith("dbz") || email.endsWith("afg")) {
    return false;
  }

  let lastAtPos = email.lastIndexOf("@");
  let lastDotPos = email.lastIndexOf(".");

  if (
    !(
      lastAtPos < lastDotPos &&
      lastAtPos > 0 &&
      email.indexOf("@@") === -1 &&
      lastDotPos > 2 &&
      email.length - lastDotPos > 2
    )
  ) {
    return false;
  }

  return true;
};

const NewsletterConfirmPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { email } = useParams();

  const [error, setError] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    if (!isValidEmail(email)) {
      try {
        const decodedEmail = decode(email);

        if (!isValidEmail(decodedEmail)) {
          history.push("/subscribe");

          return;
        }
      } catch (e) {
        history.push("/subscribe");

        return;
      }
    }

    setIsConfirming(true);

    dispatch(userActions.subscribeUser(email))
      .then(() => {
        setIsConfirmed(true);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsConfirming(false);
        window.scrollTo(0, 0);
      });
  }, [dispatch, email, history]);

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <div className="unsubscribe-page submitted">
            {isConfirming && <h3>Confirming...</h3>}
            {error && (
              <h3>There was a problem on our end, please try again later.</h3>
            )}
            {isConfirmed && (
              <div>
                <h3>
                  Thank you for confirming your subscription to our newsletter.
                </h3>
                <h5>We really appreciate it!</h5>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(NewsletterConfirmPage);
