import { getRequest, URL, postRequest, putRequest, deleteRequest, newsTypes } from '../../shared/utils';
import * as types from './actionTypes';

export function getUserCounts(userToken) {
  const url = `${URL.user}/counts`;

  var request = getRequest(url, null, userToken);

  return {
    type: types.GET_USER_COUNTS,
    payload: request
  };
}

export function saveUserVisit(email, newsletterId, emailServiceProvider) {
  let url = `${URL.user}/saveUserVisit?email=${email}`;

  if (newsletterId) {
    url += `&newsletterId=${newsletterId}`;
  }

  if (emailServiceProvider) {
    url += `&emailServiceProvider=${emailServiceProvider}`;
  }

  var request = postRequest(url);

  return {
    type: types.SUBSCRIBE_USER,
    payload: request
  };
}

export function subscribeUser(email, newsletterId, emailServiceProvider) {
  let url = `${URL.user}/subscribe?email=${email}`;

  if (newsletterId) {
    url += `&newsletterId=${newsletterId}`;
  }

  if (emailServiceProvider) {
    url += `&emailServiceProvider=${emailServiceProvider}`;
  }

  var request = postRequest(url);

  return {
    type: types.SUBSCRIBE_USER,
    payload: request
  };
}

export function unSubscribeUser(email) {
  const url = `${URL.user}/unSubscribe?email=${email}`;

  var request = postRequest(url);

  return {
    type: types.UNSUBSCRIBE_USER,
    payload: request
  };
}

export function updateUserNewsletterFrequency(email, frequency) {
  const url = `${URL.user}/updateNewsletterFrequency?email=${email}&frequency=${frequency}`;

  var request = putRequest(url);

  return {
    type: types.UPDATE_USER_NEWSLETTER_FREQUENCY,
    payload: request
  };
}