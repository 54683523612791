import React from "react";
import { isMobile } from "react-device-detect";
import { Row, Col } from "react-bootstrap";
import "pure-react-carousel/dist/react-carousel.es.css";
import NewsBox from "../../shared/newsBox/NewsBox";

import "./RelatedNews.css";

const RelatedNews = (props) => {
  const { stories, onCommentClick } = props;

  if (stories.length === 0) {
    return null;
  }

  const renderStoryRow = (stories) => {
    return (
      <div className="item">
        <Row className="rn_block">
          <Col xs={6} md={4} sm={4} className="padd">
            <NewsBox
              story={stories[0]}
              titlePosition={"below"}
              titleSize={"small"}
              showSiteLogo={false}
              // showSiteName
              onCommentClick={onCommentClick}
            />
          </Col>
          <Col xs={6} md={4} sm={4} className="padd">
            <NewsBox
              story={stories[1]}
              titlePosition={"below"}
              titleSize={"small"}
              showSiteLogo={false}
              // showSiteName
              onCommentClick={onCommentClick}
            />
          </Col>
          <Col xs={6} md={4} sm={4} className="padd">
            <NewsBox
              story={stories[2]}
              titlePosition={"below"}
              titleSize={"small"}
              showSiteLogo={false}
              // showSiteName
              onCommentClick={onCommentClick}
            />
          </Col>
        </Row>
      </div>
    );
  };

  const renderStoryRowMobile = (stories) => {
    return (
      <div className="item">
        <Row className="rn_block">
          <Col xs={6} md={4} sm={4} className="padd">
            <NewsBox
              story={stories[0]}
              titlePosition={"below"}
              titleSize={"small"}
              showSiteLogo={false}
              // showSiteName
              onCommentClick={onCommentClick}
            />
          </Col>
          <Col xs={6} md={4} sm={4} className="padd">
            <NewsBox
              story={stories[1]}
              titlePosition={"below"}
              titleSize={"small"}
              showSiteLogo={false}
              // showSiteName
              onCommentClick={onCommentClick}
            />
          </Col>
          <Col xs={6} md={4} sm={4} className="padd">
            <NewsBox
              story={stories[2]}
              titlePosition={"below"}
              titleSize={"small"}
              showSiteLogo={false}
              // showSiteName
              onCommentClick={onCommentClick}
            />
          </Col>
          <Col xs={6} md={4} sm={4} className="padd">
            <NewsBox
              story={stories[3]}
              titlePosition={"below"}
              titleSize={"small"}
              showSiteLogo={false}
              // showSiteName
              onCommentClick={onCommentClick}
            />
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className="related-news-inner">
      <h3 className="category-headding">RELATED NEWS</h3>
      <div className="headding-border" />
      {!isMobile && (
        <div>
          {renderStoryRow(stories.slice(0, stories.length - 3))}
          {renderStoryRow(stories.slice(stories.length - 3, stories.length))}
        </div>
      )}
      {isMobile && (
        <div>
          {renderStoryRowMobile(stories.slice(0, 2))}
          {renderStoryRowMobile(stories.slice(2, 4))}
          {renderStoryRowMobile(stories.slice(4, 6))}
        </div>
      )}
    </div>
  );
};

export default RelatedNews;
