/* eslint-disable no-console */
import axios from 'axios';
import { useMocks } from './mocks';

// useMocks();

export function deleteRequest(url, bearerToken = '') {
  return axios.delete(
    url,
    {
      crossdomain: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        Authorization: `Bearer ${bearerToken}`,
      },
    },
  );
}

export function postRequest(url, data, bearerToken = '', contentType = null) {
  let headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    Authorization: `Bearer ${bearerToken}`,
  };

  if (contentType) {
    headers['Content-Type'] = contentType;
    headers['Accept'] = contentType;
  }

  return axios.post(
    url, data,
    {
      crossdomain: true,
      headers
    },
  );
}

export function putRequest(url, data, bearerToken = '') {
  return axios.put(
    url, data,
    {
      crossdomain: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        Authorization: `Bearer ${bearerToken}`,
      },
    },
  );
}

export function getRequest(url, responseType, bearerToken = '') {
  return axios.get(
    url,
    {
      crossdomain: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${bearerToken}`,
      },
      responseType: responseType !== undefined ? responseType : 'json',
    },
  );
}
