/* eslint-disable */
import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import DataGrid from "react-data-grid";
import { ProgressBar, OverlayTrigger, Tooltip } from "react-bootstrap";
import MultiProgress from "react-multi-progress";
import TimePicker from "react-time-picker";
import "rc-checkbox/assets/index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericInput from "react-numeric-input";
import "react-rangeslider/lib/index.css";

import { Container, Row, Col } from "react-bootstrap";

import * as userActions from "../../../redux/actions/userActions";
import * as newsletterActions from "../../../redux/actions/newsletterActions";

import "./SendNewsletterPage.css";

const formatNumeric = (numericValue) => {
  return Number(numericValue).toLocaleString();
};

const parseNumeric = (stringValue) => {
  var noCommas = stringValue.replace(/,/g, "");

  return Number(noCommas);
};

const NumberFormatter = (value) => {
  return value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const OpenRateFormatter = (value) => {
  if (!value && value !== 0) return <div />;

  let variant = "success";

  if (value < 50) variant = "warning";
  if (value < 25) variant = "danger";

  return (
    <ProgressBar now={value} variant={variant} label={`${value.toFixed(1)}%`} />
  );
};

const FailRateFormatter = (value) => {
  if (!value && value !== 0) return <div />;

  let variant = "danger";

  if (value < 50) variant = "warning";
  if (value < 25) variant = "success";

  return (
    <ProgressBar now={value} variant={variant} label={`${value.toFixed(1)}%`} />
  );
};

const NumericEditor = ({ row, column, onRowChange }) => {
  return (
    <NumericInput
      style={{
        input: {
          width: "80px",
        },
      }}
      step={row[column.key] || row.totalSent}
      format={formatNumeric}
      parse={parseNumeric}
      value={row[column.key]}
      onChange={(e) => onRowChange({ ...row, [column.key]: e })}
    />
  );
};

const warmupColumns = [
  {
    key: "isp",
    name: "ISP",
    width: 120,
    summaryFormatter() {
      return <strong>Totals:</strong>;
    },
  },
  {
    key: "projectedUsers",
    name: "Total",
    formatter({ row }) {
      const { projectedUsers } = row;
      return NumberFormatter(projectedUsers);
    },
    summaryFormatter({ row }) {
      return <>{NumberFormatter(row.projectedUsers)}</>;
    },
  },
  {
    key: "projectedUsersAvailable",
    name: "Users",
    formatter({ row }) {
      const { projectedUsersAvailable } = row;
      return NumberFormatter(projectedUsersAvailable);
    },
    summaryFormatter({ row }) {
      return <>{NumberFormatter(row.projectedUsersAvailable)}</>;
    },
  },
  {
    key: "totalSent",
    name: "Prev Sent",
    formatter({ row }) {
      const { totalSent } = row;
      return NumberFormatter(totalSent);
    },
    summaryFormatter({ row }) {
      return <>{NumberFormatter(row.totalSent)}</>;
    },
  },
  {
    key: "maxUsers",
    name: "Max Users",
    formatter({ row, column }) {
      return NumberFormatter(row[column.key]);
    },
    editor: NumericEditor,
  },
  {
    formatter({ row, onRowChange }) {
      if (!row.maxUsers) return <></>;

      return (
        <a
          href
          onClick={() => {
            onRowChange({ ...row, maxUsers: null });
          }}
        >
          Clear
        </a>
      );
    },
    width: 10,
  },
  {
    key: "userTypesToSend",
    name: "User Types",
    width: 150,
    formatter({ row }) {
      const { isp, projectedUsers, subscribers, openers, visitors, profiles } =
        row;

      const subscriberPct = (subscribers / projectedUsers) * 100;
      const openerPct = (openers / projectedUsers) * 100;
      const visitorPct = (visitors / projectedUsers) * 100;
      const profilePct = (profiles / projectedUsers) * 100;

      return (
        <OverlayTrigger
          key={isp}
          placement={"top"}
          overlay={
            <Tooltip id={`tooltip-${isp}`}>
              {NumberFormatter(subscribers)} subscribers
              <br />
              {NumberFormatter(openers)} openers
              <br />
              {NumberFormatter(visitors)} visitors
              <br />
              {NumberFormatter(profiles)} profiles
              <br />
            </Tooltip>
          }
        >
          <div
            style={{
              marginTop: "12px",
            }}
          >
            <MultiProgress
              height={10}
              elements={[
                {
                  value: subscriberPct,
                  color: "lightBlue",
                },
                {
                  value: visitorPct,
                  color: "lightGreen",
                },
                {
                  value: openerPct,
                  color: "orange",
                },
                {
                  value: profilePct,
                  color: "#fc6c85",
                },
              ]}
            />
          </div>
        </OverlayTrigger>
      );
    },
  },
  {
    key: "inboxPct",
    name: "Inbox Placement",
    width: 150,
    formatter({ row }) {
      const { isp, inboxPct, spamPct, missingPct } = row;

      if (!inboxPct && !spamPct && !missingPct) return <div />;

      return (
        <OverlayTrigger
          key={isp}
          placement={"top"}
          overlay={
            <Tooltip id={`tooltip-${isp}`}>
              {inboxPct?.toFixed(1) || "n/a"}% inbox
              <br />
              {spamPct?.toFixed(1) || "n/a"}% spam
              <br />
              {missingPct?.toFixed(1) || "n/a"}% missing
              <br />
            </Tooltip>
          }
        >
          <div
            style={{
              marginTop: "12px",
            }}
          >
            <MultiProgress
              height={10}
              elements={[
                {
                  value: inboxPct,
                  color: "#28a745",
                },
                {
                  value: spamPct,
                  color: "#ffc107",
                },
                {
                  value: missingPct,
                  color: "#dc3545",
                },
              ]}
            />
          </div>
        </OverlayTrigger>
      );
    },
  },
  {
    key: "openRate",
    name: "Open %",
    formatter({ row }) {
      const { openRate } = row;
      return OpenRateFormatter(openRate);
    },
  },
  {
    key: "failRate",
    name: "Fail %",
    formatter({ row }) {
      const { failRate } = row;
      return FailRateFormatter(failRate);
    },
  },
].map((c) => ({ ...c }));

const SendNewsletterPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const { auth, newsletter, userCounts } =
    useSelector((state) => ({
      auth: state.auth,
      newsletter: state.newsletter.newsletter,
      userCounts: state.users.userCounts,
      sendActivity: state.newsletter.sendActivity,
      sendCounts: state.newsletter.sendCounts,
    }));

  console.log("userCounts", userCounts);

  const [loadingNewsletter, setLoadingNewsletter] = useState(false);
  const [isBreakingNews, setIsBreakingNews] = useState(false);
  const [loadingUserCounts, setLoadingUserCounts] = useState(false);
  const [savingUpdates, setSavingUpdates] = useState(false);
  const [schedulingSend, setSchedulingSend] = useState(false);
  const [scheduledSend, setScheduledSend] = useState(false);
  const [scheduleTime, setScheduleTime] = useState("03:00");
  const [refreshingActivityList, setRefreshingActivityList] = useState(false);
  const [warmupRows, setWarmupRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState(null);
  const [totalsRow, setTotalsRow] = useState({});
  const [currentSort, setCurrentSort] = useState({
    sortColumn: "isp",
    sortDirection: "ASC",
  });
  const [sortColumns, setSortColumns] = useState([]);
  const [userStats, setUserStats] = useState([]);
  const [visitorStats, setVisitorStats] = useState([]);
  const [openerStats, setOpenerStats] = useState([]);
  const [providerStats, setProviderStats] = useState([]);
  const [openersAvailable, setOpenersAvailable] = useState([]);
  const [sendProvider, setSendProvider] = useState({
    testEmailAddress: null,
    toSend: 0,
    sendToVisitorsCount: 0,
    sendToOpenersCount: 0,
    maxSendToUsersCount: 0,
    maxWarmupSendUsers: 0,
    daysThreshold: 0,
    sendToUsers: [],
    sendToVisitors: [],
    sendToOpeners: [],
    sendToProfiles: [],
  });

  useEffect(() => {
    getNewsletter();
    getUserCounts();
  }, []);

  useEffect(() => {
    updateProfileConfig();
    updateWarmupGrid();
  }, [userCounts]);

  const onScheduleTimeChange = (time) => {
    setScheduleTime(time);
  };


  const handleRowChanged = (rows) => {
    setWarmupRows([...rows]);
  };

  const updateProfileConfig = () => {
    const { totalToSend, maxUsers, daysThreshold } = userCounts;

    setSendProvider({
      ...sendProvider,
      totalToSend,
      maxUsers,
      daysThreshold,
    });
  };

  const updateWarmupGrid = () => {
    const { projectedWarmupCounts } = userCounts;

    let warmupRows = [];

    let totalsRow = {
      pauseSends: false,
      isp: "Totals:",
      projectedUsers: 0,
      projectedUsersAvailable: 0,
      projectedProfiles: 0,
      projectedProfilesAvailable: 0,
      totalSent: 0,
      openRate: 0,
      failRate: 0,
    };

    for (let i = 0; i < projectedWarmupCounts.length; i++) {
      let projectedWarmupCount = projectedWarmupCounts[i];

      const {
        id,
        pauseSends,
        name,
        users,
        usersAvailable,
        subscribers,
        visitors,
        openers,
        profiles,
        profilesAvailable,
        totalSent,
        nbrOpens,
        nbrFails,
        maxUsers,
        inboxPct,
        spamPct,
        missingPct,
      } = projectedWarmupCount;

      let warmupRow = {
        id,
        pauseSends,
        isp: name,
        projectedUsers: users,
        projectedUsersAvailable: usersAvailable,
        projectedProfiles: profiles,
        projectedProfilesAvailable: profilesAvailable,
        subscribers,
        visitors,
        openers,
        profiles,
        openRate: ((nbrOpens || 0) / totalSent) * 100,
        failRate: (nbrFails / totalSent) * 100,
        users,
        maxUsers,
        inboxPct,
        spamPct,
        missingPct,
        totalSent,
      };

      warmupRows.push({ ...warmupRow });

      totalsRow.projectedUsers += warmupRow.projectedUsers;
      totalsRow.projectedUsersAvailable += warmupRow.projectedUsersAvailable;
      totalsRow.projectedProfiles += warmupRow.projectedProfiles;
      totalsRow.projectedProfilesAvailable +=
        warmupRow.projectedProfilesAvailable;
      totalsRow.subscribers += warmupRow.subscribers;
      totalsRow.visitors += warmupRow.visitors;
      totalsRow.openers += warmupRow.openers;
      totalsRow.profiles += warmupRow.profiles;
      totalsRow.totalSent += warmupRow.totalSent;
    }

    setWarmupRows([...warmupRows]);
    setTotalsRow(totalsRow);
  };

  const getNewsletter = () => {
    setLoadingNewsletter(true);

    dispatch(newsletterActions.getNewsletter(auth.token))
      .catch((err) => {
        console.log(err.message);

        history.push({
          pathname: "/login",
          state: { redirect: "/admin/sendNewsletter" },
        });
      })
      .finally(() => {
        setLoadingNewsletter(false);
      });
  };

  const getUserCounts = () => {
    setLoadingUserCounts(true);

    dispatch(userActions.getUserCounts(auth.token))
      .catch((err) => {
        console.log(err.message);

        history.push({
          pathname: "/login",
          state: { redirect: "/admin/sendNewsletter" },
        });
      })
      .finally(() => {
        setLoadingUserCounts(false);
      });
  };

  const getUsersAvailable = () => {
    const maxUsersAvailable = getMaxUsersAvailable();

    const { maxSendToUsersCount } = sendProvider;

    return maxUsersAvailable - maxSendToUsersCount;
  };

  const getMaxUsersAvailable = () => {
    const { users } = userCounts;

    let userCount = 0;

    users.forEach((u) => {
      userCount += u.userCount;
    });

    return userCount;
  };

  const handleMaxUserChange = (value) => {
    const usersAvail = getUsersAvailable();

    let maxSendToUsersCount = value;
    const espState = sendProvider;

    const prevSendToUsersCount = espState.maxSendToUsersCount;

    if (maxSendToUsersCount > prevSendToUsersCount && !usersAvail) {
      return;
    }

    if (maxSendToUsersCount - prevSendToUsersCount >= usersAvail) {
      maxSendToUsersCount = prevSendToUsersCount + usersAvail;
    }

    setSendProvider({
      ...sendProvider,
      toSend: maxSendToUsersCount,
      maxSendToUsersCount,
    });
  };



  const getComparator = (sortColumn) => {
    switch (sortColumn) {
      case "pauseSends":
        return (a, b) =>
          a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
      case "isp":
        return (a, b) => a[sortColumn].localeCompare(b[sortColumn]);
      case "projectedUsers":
      case "projectedUsersAvailable":
      case "totalSent":
      case "maxUsers":
      case "userTypesToSend":
      case "openRate":
      case "failRate":
      case "inboxPct":
      case "spamPct":
        return (a, b) => a[sortColumn] - b[sortColumn];
      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  };

  const sortedRows = () => {
    if (sortColumns.length === 0) return warmupRows;

    const sortedRows = [...warmupRows];

    sortedRows.sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === "ASC" ? compResult : -compResult;
        }
      }

      return 0;
    });

    return sortedRows;
  };

  const handleSortColumnChange = (columns) => {
    setSortColumns([...columns]);
  };

  const handleDaysThresholdChange = (value) => {
    setSendProvider({
      ...sendProvider,
      daysThreshold: value,
    });
  };

  const clearIspProfileSettings = () => {
    warmupRows.forEach((r) => {
      r.profilesMin = null;
      r.profilesMax = null;
      r.profilePct = null;
    });

    setWarmupRows([...warmupRows]);
  };

  const renderAutoControls = () => {
    const espState = sendProvider;

    const { totalToSend, maxUsers, daysThreshold } = espState;

    return (
      <div>
        <div className={"number-to-send-container"}>
          <span className={"number-to-send-label"}># To Send:</span>
          <span>{NumberFormatter(totalToSend)}</span>
        </div>
        <div className={"auto-controls-container"}>
          <span className={"config-control-label"}>Max Users:</span>
          <span className={"config-control"}>
            <NumericInput
              min={0}
              step={1000}
              format={formatNumeric}
              parse={parseNumeric}
              value={maxUsers}
              className={"profile-config-textbox"}
              onChange={(value) => handleMaxUserChange(value)}
            />
          </span>
          <span className={"config-control-label"}>Days Threshold:</span>
          <span className={"config-control"}>
            <NumericInput
              min={0}
              step={10}
              format={formatNumeric}
              parse={parseNumeric}
              value={daysThreshold}
              className={"profile-config-textbox"}
              onChange={(value) => handleDaysThresholdChange(value)}
            />
          </span>
          <span className={"config-control"}>
            <button className="btn btn-style" onClick={clearIspProfileSettings}>
              Clear Settings
            </button>
          </span>
          <div className={"warmup-grid"}>
            <DataGrid
              rowKeyGetter={(row) => {
                return row.id;
              }}
              columns={warmupColumns}
              rows={sortedRows()}
              defaultColumnOptions={{
                sortable: true,
                // resizable: true
              }}
              // selectedRows={selectedRows}
              // onSelectedRowsChange={(rows) => {
              //   console.log(rows);
              //   setState({ selectedRows: [...rows] })
              // }}
              onRowsChange={handleRowChanged}
              sortColumns={sortColumns}
              onSortColumnsChange={handleSortColumnChange}
              summaryRows={[totalsRow]}
              className="warmup-grid"
            />
          </div>
        </div>
      </div>
    );
  };

  const manageNewsletter = () => {
    history.push("/admin/manageNewsletter");
  };

  const saveSettings = () => {
    const { maxUsers, daysThreshold } = sendProvider;

    setSavingUpdates(true);

    const request = {
      maxUsers,
      daysThreshold,
      newsletterIspSendSettings: warmupRows,
    };

    dispatch(newsletterActions.saveSendSettings(request, auth.token))
      .then(() => {
        toast.success("Settings Saved, Refreshing...", {
          position: toast.POSITION.TOP_LEFT,
        });

        getUserCounts();
      })
      .catch((err) => {
        toast.error(`Error Saving Settings: ${err}`, {
          position: toast.POSITION.TOP_LEFT,
        });
      })
      .finally(() => {
        setSavingUpdates(false);
      });
  };

  const scheduleNewsletter = () => {
    const { id } = newsletter;

    setSchedulingSend(true);

    const { visitors, openers } = userCounts;
    const {
      sendToVisitorsCount,
      sendToOpenersCount,
      maxSendToUsersCount,
      maxSendToProfilesCount,
    } = sendProvider;

    const sendToVisitors = [];
    const sendToOpeners = [];

    const buildSendToUsers = (users, sendToUsers, sendToCount) => {
      let usersAdded = 0;

      while (usersAdded < sendToCount) {
        for (let i = 0; i < users.length; i++) {
          const user = users[i];

          let sendToProvider = 1;

          const { providerStats } = user;

          if (providerStats) {
            const { pctInbox, pctBulk, pctMissing } = providerStats;

            if (pctInbox == 100) {
              sendToProvider = 5;
            } else {
              if (pctInbox > 50) {
                sendToProvider = 3;
              } else {
                if (pctMissing == 100 || pctMissing > pctInbox + pctBulk) {
                  break;
                }
              }
            }
          }

          var sendToUser = sendToUsers.find((u) => u.name == user.name);

          if (sendToUser) {
            if (sendToUser.userCount < user.userCount) {
              if (sendToUser.userCount < user.userCount + sendToProvider) {
                sendToUser.userCount += sendToProvider;
                usersAdded += sendToProvider;
              } else {
                sendToUser.userCount = user.userCount;
                usersAdded += user.userCount;
              }
            }

            if (usersAdded >= sendToCount) {
              break;
            }
          } else {
            sendToUsers.push({
              name: user.name,
              userCount: sendToProvider,
            });

            usersAdded += sendToProvider;

            if (usersAdded >= sendToCount) {
              break;
            }
          }
        }

        if (usersAdded >= sendToCount) {
          break;
        }
      }
    };

    buildSendToUsers(visitors, sendToVisitors, sendToVisitorsCount);
    buildSendToUsers(openers, sendToOpeners, sendToOpenersCount);

    setSendProvider({
      ...sendProvider,
      sendToVisitors: [...sendToVisitors],
      sendToOpeners: [...sendToOpeners],
    });

    var sendToProvider = {
      autoDistribute: true,
      breakingNews: isBreakingNews,
      maxSendToUsersCount,
      maxSendToProfilesCount,
      sendToVisitors,
      sendToOpeners,
      sendToProfiles: sendProvider.sendToProfiles,
    };

    dispatch(newsletterActions.generateContent(id, auth.token));

    dispatch(
      newsletterActions.scheduleNewsletter(
        { newsletterId: id, sendProvider: { ...sendToProvider }, scheduleTime },
        auth.token
      )
    )
      .then(() => {
        toast.success("Newsletter Send Scheduled", {
          position: toast.POSITION.TOP_LEFT,
        });
      })
      .catch((err) => {
        toast.error(`Error Scheduling Newsletter Send: ${err}`, {
          position: toast.POSITION.TOP_LEFT,
        });
      })
      .finally(() => {
        setSchedulingSend(false);
        setScheduledSend(true);
      });
  };

  const { selectedStories, subject } = newsletter;

  return (
    <div className="send-newsletter">
      <ToastContainer />
      <section className="block-inner">
        <Container>
          <Row>
            <Col sm={12}>
              <span className="newsletter-send-title">
                {loadingNewsletter && "Loading..."}
                {!loadingNewsletter && selectedStories.length == 0 && (
                  <h1>No newsletters set to go out</h1>
                )}
                {!loadingNewsletter && selectedStories.length > 0 && (
                  <>
                    Newsletter To Send: {subject} ({selectedStories.length}{" "}
                    stories)
                  </>
                )}
              </span>
              <button
                className="btn btn-style manage"
                onClick={manageNewsletter}
              >
                Manage
              </button>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row>
          <Col sm={12}>
            <div className="warmup">
              {loadingUserCounts && <>Loading Counts...</>}
            </div>
            {renderAutoControls()}
            <div className="button-controls">
              <button className="btn btn-style save" onClick={saveSettings}>
                {savingUpdates ? "Saving..." : "Save"}
              </button>
              <button
                className="btn btn-style schedule"
                onClick={scheduleNewsletter}
              >
                {isBreakingNews &&
                  (schedulingSend
                    ? "Sending..."
                    : scheduledSend
                    ? "Newsletter Sent"
                    : "Send")}
                {!isBreakingNews &&
                  (schedulingSend
                    ? "Scheduling Send..."
                    : scheduledSend
                    ? "Send Scheduled"
                    : "Schedule")}
              </button>
              {!isBreakingNews && (
                <TimePicker
                  onChange={onScheduleTimeChange}
                  value={scheduleTime}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(SendNewsletterPage);
