import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from "react-device-detect";
import HeroCarousel from './heroCarousel';
import NewsBox from '../../shared/newsBox/NewsBox';

const propTypes = {
  stories: PropTypes.array,
  onCommentClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  isAdmin: PropTypes.bool
}

const defaultProps = {
  stories: [],
  onCommentClick: () => { },
  onDeleteClick: () => { console.log('delete from recent') },
  isAdmin: false
}

const RecentNews = (props) => {
  const {
    stories,
    onCommentClick,
    onDeleteClick,
    isAdmin,
  } = props;

  if (stories.length === 0) {
    return null;
  }

  if (isMobile) {
    return (
      <section className="recent_news_inner">
        <h3 className="category-headding ">RECENT NEWS</h3>
        <div className="headding-border"></div>
        <div className="row rn_block">
          {stories.map((story, i) => (
            <div className={`col-md-4 col-sm-6 padd ${i === stories.length - 1 && 'd-sm-none d-md-block'}`}>
              <NewsBox
                story={story}
                titlePosition={'below'}
                titleSize={'medium'}
                showCategoryBadge
                onCommentClick={onCommentClick}
                showGradientOverlay
                isAdmin={isAdmin}
                lazyLoadImage={!isAdmin}
              />
            </div>
          ))}
        </div>
      </section>
    )
  }

  const heroes = stories.slice(0, stories.length - 3);
  const others = stories.slice(stories.length - 3, stories.length)

  return (
    <section className="recent_news_inner">
      <h3 className="category-headding ">RECENT NEWS</h3>
      <div className="headding-border"></div>
      <div className="row carousel">
        <div id="content-slide" className="owl-carousel owl-theme">
          <HeroCarousel
            heroStories={heroes}
            onCommentClick={onCommentClick}
            onDeleteClick={onDeleteClick}
            isAdmin={isAdmin}
            lazyLoadImage={!isAdmin}
          />
        </div>
      </div>
      <div className="row rn_block">
        {others.map((story, i) => (
          <div className={`col-md-4 col-sm-6 padd ${i === others.length - 1 && 'd-sm-none d-md-block'}`}>
            <NewsBox
              story={story}
              titlePosition={'below'}
              titleSize={'medium'}
              showCategoryBadge
              onCommentClick={onCommentClick}
              onDelete={onDeleteClick}
              showGradientOverlay
              isAdmin={isAdmin}
              lazyLoadImage={!isAdmin}
            />
          </div>
        ))}
      </div>
    </section>
  )
}

RecentNews.propTypes = propTypes;
RecentNews.defaultProps = defaultProps;

export default RecentNews;
