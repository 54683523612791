import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';

const Navbar = () => {
  const activeStyle = { color: "#F15B2A" };

  return (
    <div className="container d-none d-sm-block">
      <nav className="navbar">
        {/* <div className="collapse navbar-collapse"> */}
        <ul className="nav navbar-nav">
          <li className="nav-item active"><NavLink to="/" className="nav-link category02">HOME</NavLink></li>
          <li className="nav-item"><NavLink to="/category/us-news" className="nav-link category01">US NEWS</NavLink></li>
          <li className="nav-item"><NavLink to="/category/entertainment" className="nav-link category03">ENTERTAINMENT</NavLink></li>
          <li className="nav-item"><NavLink to="/category/tabloid" className="nav-link category02">TABLOID</NavLink></li>
          <li className="nav-item"><NavLink to="/category/politics" className="nav-link category01">POLITICS</NavLink></li>
          <li className="nav-item"><NavLink to="/category/business" className="nav-link category05">BUSINESS</NavLink></li>
          <li className="nav-item"><NavLink to="/category/sports" className="nav-link category01">SPORTS</NavLink></li>
          <li className="nav-item"><NavLink to="/category/technology" className="nav-link category02">TECHNOLOGY</NavLink></li>
          <li className="nav-item"><NavLink to="/category/alternative" className="nav-link category06">ALTERNATIVE</NavLink></li>
          <li className="nav-item"><NavLink to="/category/us-newspapers" className="nav-link category02">US NEWSPAPERS</NavLink></li>
          <li className="nav-item"><NavLink to="/category/international" className="nav-link category04">INTERNATIONAL</NavLink></li>
        </ul>
        {/* </div> */}
      </nav>
    </div>
  )
}

export default Navbar;