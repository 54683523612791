import * as types from '../actions/actionTypes';
import { saveAuthState, loadAuthState } from '../../shared/utils/helperFunctions/localStorage';

const initialState = loadAuthState() ||
  {
    token: null,
    loginError: false,
  };

export default function authReducer(state = { ...initialState, loginError: false }, action) {
  switch (action.type) {
    case types.LOGIN:
      if (action.payload.data.error) {
        return saveAuthState({
          ...state,
          token: null,
          data: null,
          loginError: true,
          loginErrorMessage: action.payload.data.error_description,
        });
      }

      return saveAuthState({
        ...state,
        token: action.payload.data.access_token,
        data: JSON.parse(window.atob(action.payload.data.access_token.split('.')[1])),
        loginError: false,
        loginErrorMessage: null,
      });

    case types.LOGOUT:
      return saveAuthState({
        ...state,
        token: null,
        data: null,
        loginError: false,
        loginErrorMessage: null,
      });

    default:
      return state;
  }
}