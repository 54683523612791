// const baseURL = 'https://localhost:44323/api/'; // Dev
const baseURL = 'https://api.headlineintime.com/api/'; // Test Prod
// const baseURL = 'https://headlineintime-service-aspnetcore.azurewebsites.net/api/'; // Prod

export default {
  baseUrl: baseURL,
  headline: `${baseURL}Headlines`,
  user: `${baseURL}Users`,
  category: `${baseURL}SiteCategories`,
  site: `${baseURL}Sites`,
  newsletter: `${baseURL}Newsletter`,
  auth: `${baseURL}Auth`
};
