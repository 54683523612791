import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const propTypes = {
  categories: PropTypes.array
}

const defaultProps = {
  categories: []
}

const Categories = (props) => (
  <aside>
    <h3 className="category-headding ">CATEGORIES</h3>
    <div className="headding-border bg-color-2"></div>
    <div className="cats-widget">
      <ul>
        {props.categories.map((category) => (
          <li className="">
            <NavLink to={`/category/${category.urlFriendlyName}`}>
              {category.name}
            </NavLink> <span>{category.numberOfArticles}</span>
          </li>
        ))}
      </ul>
    </div>
  </aside>
)

Categories.propTypes = propTypes;
Categories.defaultProps = defaultProps;

export default Categories;