import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";

import * as userActions from "../../../../redux/actions/userActions";

const SubscribeForm = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [error, setError] = useState(false);

  const isValidEmail = (email) => {
    let lastAtPos = email.lastIndexOf("@");
    let lastDotPos = email.lastIndexOf(".");

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        email.indexOf("@@") === -1 &&
        lastDotPos > 2 &&
        email.length - lastDotPos > 2
      )
    ) {
      return false;
    }

    return true;
  };

  const onEnteringEmail = (value) => {
    setEmail(value);
  };

  const onEmailSubmit = () => {
    setIsInvalidEmail(false);
    setError(false);
    setSubmitted(false);

    if (!isValidEmail(email)) {
      setIsInvalidEmail(true);
      return;
    }

    dispatch(userActions.subscribeUser(email))
      .then(() => {
        setSubmitted(true);
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <div className="footer-box">
      <div className="newsletter-inner">
        <h3 className="category-headding ">NEWSLETTER</h3>
        <div className="headding-border"></div>
        {!submitted && (
          <div>
            <p>Enter your email address for our mailing list!</p>
            <input
              type="text"
              value={email}
              className="form-control"
              id="email"
              name="email"
              onChange={(e) => onEnteringEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
            <button
              type="button"
              className="btn btn-style"
              onClick={onEmailSubmit}
            >
              Subscribe
            </button>
          </div>
        )}
        {isInvalidEmail && <p>Email is invalid. Please re-enter.</p>}
        {submitted && <p>Your email has been submitted!</p>}
        {error && (
          <p>
            There was a problem submitting your email. Please try again later.
          </p>
        )}
      </div>
    </div>
  );
};

export default withRouter(SubscribeForm);
