import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter, useHistory, useParams } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import ReactGA from "react-ga";
import dogWithPaperInMouth from "../../assets/images/dog-with-paper-in-mouth.jpg";
import * as userActions from "../../redux/actions/userActions";

import "./UpdatePreferencesPage.css";

const FREQUENCY_TYPE_UNSUBSCRIBE = "5";

const isValidEmail = (email) => {
  let lastAtPos = email.lastIndexOf("@");
  let lastDotPos = email.lastIndexOf(".");

  if (
    !(
      lastAtPos < lastDotPos &&
      lastAtPos > 0 &&
      email.indexOf("@@") === -1 &&
      lastDotPos > 2 &&
      email.length - lastDotPos > 2
    )
  ) {
    return false;
  }

  return true;
};

const UpdatePreferencesPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { email } = useParams();

  const [emailToSubmit, setEmailToSubmit] = useState(email);
  const [frequency, setFrequency] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  useEffect(() => {
    if (!email) {
      return;
    }

    if (!isValidEmail(email)) {
      setFrequency(FREQUENCY_TYPE_UNSUBSCRIBE);
    }
  }, [email]);

  const trackPageView = () => {
    ReactGA.pageview(`/update-preferences/${email}`);
  };

  const handleEmailChange = (e) => {
    setEmailToSubmit(e.target.value);
  }

  const handleOptionChange = (e) => {
    setFrequency(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (frequency === 0) {
      setValidationMessage("Please select a frequency.");
      return false;
    }

    if (!emailToSubmit || !isValidEmail(emailToSubmit)) {
      setValidationMessage("Please enter valid email.");
      return false;
    }

    if (frequency === FREQUENCY_TYPE_UNSUBSCRIBE) {
      history.push(`/user/unsubscribe/${emailToSubmit}`);
    } else {
      trackPageView();
      dispatch(
        userActions.updateUserNewsletterFrequency(emailToSubmit, frequency)
      ).finally(() => {
        setSubmitted(true);
        window.scrollTo(0, 0);
      });
    }
  };

  if (submitted) {
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <div className="update-preferences-page submitted">
              <h3>Headline In Time</h3>
              <h5>Success!</h5>
              <p>We have received your updated frequency preferences.</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div className="fade-in">
      <div className="update-preferences-page">
        <Container>
          <Row>
            <Col md={5} xs={12}>
              <img src={dogWithPaperInMouth} alt="Dog" />
            </Col>
            <Col md={7} xs={12}>
              <h5>How much Headline In Time do you want in your life?</h5>
              <b>Frequency Preferences</b>
              <form>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value={1}
                      checked={frequency === "1"}
                      onChange={handleOptionChange}
                    />
                    How about once a week? Let's take it slow, Headline In Time.
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value={2}
                      checked={frequency === "2"}
                      onChange={handleOptionChange}
                    />
                    A few times a month sounds about right.
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value={3}
                      checked={frequency === "3"}
                      onChange={handleOptionChange}
                    />
                    Once a month. I like you, Headline In Time. LIKE.
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value={4}
                      checked={frequency === "4"}
                      onChange={handleOptionChange}
                    />
                    It's not you, it's me Headline In Time. I need a 30-day
                    break.
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value={5}
                      checked={frequency === "5"}
                      onChange={handleOptionChange}
                    />
                    Unsubscribe. *Sniff*. It's over, Headline In Time.
                  </label>
                </div>
                <div className="text-box">
                  <div>
                    <strong>
                      Email Address <span className="red-asterisk">*</span>
                    </strong>
                  </div>
                  <input
                    className="subject"
                    type="text"
                    value={emailToSubmit}
                    size={40}
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="validation-message">{validationMessage}</div>
                <button
                  type="submit"
                  className="btn btn-style"
                  onClick={handleSubmit}
                >
                  Update Preferences
                </button>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default withRouter(UpdatePreferencesPage);
