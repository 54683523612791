import { getRequest, URL, postRequest, putRequest, deleteRequest, newsTypes } from '../../shared/utils';
import * as types from './actionTypes';

export function getNewsletter(userToken) {
  const url = URL.newsletter;

  var request = getRequest(url, null, userToken);

  return {
    type: types.GET_NEWSLETTER,
    payload: request
  };
}

export function getNewsletterAutoGenerated(userToken) {
  const url = `${URL.newsletter}/autoGenerated`;

  var request = getRequest(url, null, userToken);

  return {
    type: types.GET_NEWSLETTER,
    payload: request
  };
}

export function getNewsletters(userToken) {
  const url = `${URL.newsletter}/all`;

  var request = getRequest(url, null, userToken);

  return {
    type: types.GET_NEWSLETTERS,
    payload: request
  };
}

export function getNewsletterSendActivity(id, userToken) {
  const url = `${URL.newsletter}/getActivity?newsletterId=${id}`;

  var request = getRequest(url, null, userToken);

  return {
    type: types.GET_NEWSLETTER_SEND_ACTIVITY,
    payload: request
  };
}

export function getNewsletterFails(isp, newsletterId, userToken) {
  let url = `${URL.newsletter}/getFails?isp=${isp}`;

  if (newsletterId) {
    url += `&newsletterId=${newsletterId}`;
  }

  var request = getRequest(url, null, userToken);

  return {
    type: types.GET_NEWSLETTER_FAILS,
    payload: request
  };
}

export function getNewsletterIsps(userToken) {
  let url = `${URL.newsletter}/getIsps`;

  var request = getRequest(url, null, userToken);

  return {
    type: types.GET_NEWSLETTER_ISPS,
    payload: request
  };
}

export function getNewsletterIspActivity(id, emailServiceProvider, userToken) {
  let url = `${URL.newsletter}/getIspActivity`;

  if (id) {
    url += `?newsletterId=${id}`;
  }

  if (emailServiceProvider) {
    url += `&emailServiceProvider=${emailServiceProvider}`;
  }

  var request = getRequest(url, null, userToken);

  return {
    type: types.GET_NEWSLETTER_ISP_ACTIVITY,
    payload: request
  };
}

export function getNewsletterDailyActivity(startDate, endDate, isp, userToken) {
  let url = `${URL.newsletter}/getDailyActivity?startDate=${startDate}&endDate=${endDate}`;

  if (isp) {
    url += `&isp=${isp}`;
  }

  var request = getRequest(url, null, userToken);

  return {
    type: types.GET_NEWSLETTER_DAILY_ACTIVITY,
    payload: request
  };
}

export function getNewsletterIspCounts(id, emailServiceProvider, userToken) {
  let url = `${URL.newsletter}/getIspCounts`;

  if (id) {
    url += `?newsletterId=${id}`;
  }

  if (emailServiceProvider) {
    url += `&emailServiceProvider=${emailServiceProvider}`;
  }

  var request = getRequest(url, null, userToken);

  return {
    type: types.GET_NEWSLETTER_ISP_COUNTS,
    payload: request
  };
}

export function getNewsletterIspBoxPlacements(id, emailServiceProvider, userToken) {
  let url = `${URL.newsletter}/getIspBoxPlacements`;

  if (id) {
    url += `?newsletterId=${id}`;
  }

  if (emailServiceProvider) {
    url += `&emailServiceProvider=${emailServiceProvider}`;
  }

  var request = getRequest(url, null, userToken);

  return {
    type: types.GET_NEWSLETTER_ISP_BOX_PLACEMENTS,
    payload: request
  };
}

export function getNewsletterSendCounts(id, userToken) {
  let url = `${URL.newsletter}/getSendCounts`;

  if (id) {
    url += `?newsletterId=${id}`;
  }

  var request = getRequest(url, null, userToken);

  return {
    type: types.GET_NEWSLETTER_SEND_COUNTS,
    payload: request
  };
}

export function getNewsletterSpamReport(id, userToken) {
  let url = `${URL.newsletter}/spamReport?newsletterId=${id}`;

  var request = getRequest(url, null, userToken);

  return {
    type: types.GET_NEWSLETTER_SPAM_REPORT,
    payload: request
  };
}

export function saveNewsletter(newsletter, userToken) {
  const url = URL.newsletter;

  var request = postRequest(url, newsletter, userToken);

  return {
    type: types.SAVE_NEWSLETTER,
    payload: request
  };
}

export function saveNewsletterArticle(id, keywords, userToken) {
  const url = `${URL.newsletter}/article?id=${id}&keywords=${keywords}`;

  var request = postRequest(url, null, userToken);

  return {
    type: types.SAVE_NEWSLETTER_ARTICLE,
    payload: request
  };
}

export function saveNewsletterSendCounts(sendRequest, userToken) {
  const url = `${URL.newsletter}/send`;

  var request = postRequest(url, sendRequest, userToken);

  return {
    type: types.SEND_NEWSLETTER,
    payload: request
  };
}

export function sendNewsletter(sendRequest, userToken) {
  const url = `${URL.newsletter}/send`;

  var request = postRequest(url, sendRequest, userToken);

  return {
    type: types.SEND_NEWSLETTER,
    payload: request
  };
}

export function saveSendSettings(settings, userToken) {
  const url = `${URL.newsletter}/saveSendSettings`;

  var request = postRequest(url, settings, userToken);

  return {
    type: types.SEND_NEWSLETTER,
    payload: request
  };
}

export function scheduleNewsletter(sendRequest, userToken) {
  const url = `${URL.newsletter}/schedule`;

  var request = postRequest(url, sendRequest, userToken);

  return {
    type: types.SEND_NEWSLETTER,
    payload: request
  };
}

export function generateSubject(articleTitles, userToken) {
  const url = `${URL.newsletter}/generateSubject`;

  var request = postRequest(url, articleTitles, userToken);

  return {
    type: types.GENERATE_SUBJECT,
    payload: request
  };
}

export function generateContent(newsletterId, userToken) {
  const url = `${URL.newsletter}/generateContent?newsletterId=${newsletterId}`;

  var request = postRequest(url, null, userToken);

  return {
    type: types.GENERATE_CONTENT,
    payload: request
  };
}

export function sendTestNewsletter(sendRequest, userToken) {
  const url = `${URL.newsletter}/sendTest`;

  var request = postRequest(url, sendRequest, userToken);

  return {
    type: types.SEND_NEWSLETTER,
    payload: request
  };
}

export function uploadNewsletterAdActivity(csvText, userToken) {
  const url = `${URL.newsletter}/uploadAdActivity`;

  var request = postRequest(url, csvText, userToken);

  return {
    type: types.SEND_NEWSLETTER,
    payload: request
  };
}