const stateKeyName = 'HEADLINE_IN_TIME_AUTH_STATE';

export const loadAuthState = () => {
  try {
    const serializedState = localStorage.getItem(stateKeyName);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveAuthState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(stateKeyName, serializedState);
  } catch (err) {
    // Ignore write errors.
  }
  return state;
};
