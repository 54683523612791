import React, { useState, useEffect } from "react";
import { withRouter, useHistory, useParams } from "react-router";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga";
import { isMobile } from "react-device-detect";
import Sticky from "react-sticky-el";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as headlineActions from "../../redux/actions/headlineActions";
import * as categoryActions from "../../redux/actions/categoryActions";
import * as userActions from "../../redux/actions/userActions";
import * as authActions from "../../redux/actions/authActions";

import TopNews from "./topNews";
import RecentNews from "./recentNews";
import { Container, Row, Col } from "react-bootstrap";
import CategoryNews from "./categoryNews";
import Categories from "../shared/categories";
import PopularViewedNews from "../shared/popularViewedNews";
import GoogleAd from "../shared/googleAds";
import SearchBox from "../search/searchBox";

import "./HomePage.css";
import MakeUniqueModal from "./makeUniqueModal";

const HomePage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { userAction, email, storyUrl, storyEmail } = useParams();

  const { categories, homePageNews, auth, isAdmin, displayMode } =
    useSelector((state) => ({
      categories: state.categories,
      homePageNews: state.headlines.homePageNews,
      story: state.headlines.story,
      auth: state.auth,
      isAdmin: state.auth.data && state.auth.data.role === "admin",
      displayMode: state.auth.data && state.auth.data.displayMode,
    }));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [uniqueModalOpen, setUniqueModalOpen] = useState(false);
  const [selectedText, setSelectedText] = useState("");

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseUp);

    let qParms = queryString.parse(props.location.search);

    if (qParms.logout) {
      dispatch(authActions.logout());
      history.push("/");
    }

    let path = "/";

    if (userAction) {
      path += `user/${userAction}/${email}`;
    }

    if (storyUrl) {
      path += `story/${storyUrl}`;

      if (storyEmail) {
        path += `/${storyEmail}`;
      }
    }

    ReactGA.pageview(path);

    dispatch(categoryActions.getCategories()).catch(() => {
      setError(true);
    });

    setLoading(true);

    dispatch(headlineActions.getHomePageHeadlines())
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });

    if (userAction !== "visit" && email) {
      setUniqueModalOpen(true);
    }

    if (storyUrl) {
      if (storyEmail) {
        dispatch(userActions.saveUserVisit(storyEmail));
      }

      dispatch(headlineActions.getHeadline(storyUrl))
        .then(() => {
          ReactGA.pageview(path);
        })
        .catch(() => {
          setError(true);
        });
    }
  }, []);

  const handleMouseUp = () => {
    if (!isAdmin) {
      return;
    }

    let selectedText;

    if (window.getSelection) {
      selectedText = window.getSelection().toString();
    } else if (document.selection && document.selection.type !== "Control") {
      selectedText = document.selection.createRange().text;
    }

    if (selectedText && selectedText.length > 0) {
      setSelectedText(selectedText);
      setUniqueModalOpen(true);
    }
  };

  const renderUniqueModal = () => {
    return (
      <MakeUniqueModal
        selectedText={selectedText}
        isOpen={uniqueModalOpen}
        onTextChanged={(changedText) => {
          setSelectedText(changedText);
        }}
        onClose={handleUniqueModalClose}
        onMakeUnique={handleMakeUnique}
      />
    );
  };

  const handleUniqueModalClose = () => {
    setUniqueModalOpen(false);
  };

  const handleMakeUnique = () => {
    if (!selectedText || selectedText.trim() === "") {
      toast.error("Please enter word or phrase to make unique", {
        position: toast.POSITION.TOP_LEFT,
      });

      return;
    }

    dispatch(headlineActions.makeUnique(selectedText, auth.token))
      .then(() => {
        toast.success(`Successfully added ${selectedText} to unique list`, {
          position: toast.POSITION.TOP_LEFT,
        });

        setUniqueModalOpen(false);

        dispatch(headlineActions.getHomePageHeadlines());
      })
      .catch((err) => {
        toast.error(`Error Adding to Unique List: ${err}`, {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  };

  const handleStoryDeleted = (story) => {
    dispatch(headlineActions.deleteStory(story, auth.token))
      .then(() => {
        toast.success(`Successfully deleted ${story.title}`, {
          position: toast.POSITION.TOP_LEFT,
        });

        dispatch(headlineActions.getHomePageHeadlines());
      })
      .catch((err) => {
        toast.error(`Error deleting story: ${err}`, {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  };

  const renderCategoryNews = (categoryId, stories) => (
    <CategoryNews
      categoryId={categoryId}
      stories={stories}
      onDeleteClick={handleStoryDeleted}
      isAdmin={isAdmin && displayMode === "admin"}
    />
  );

  if (loading) {
    return <div id="se-pre-con"></div>;
  }

  if (error) {
    return (
      <div className="home-page fade-in">
        <Container>
          <Row>
            <Col sm={12}>
              <div className="error-message">
                <h1>
                  Oops, something went wrong on our end. Please try again in a
                  few moments.
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  if (!homePageNews) {
    return;
  }

  const {
    topNews,
    recentNews,
    mostPopularNews,
    mostViewedNews,
    politicsNews,
    businessNews,
    worldNews,
    techNews,
    sportsNews,
    entertainmentNews,
    alternativeNews,
    usNewspaperNews,
    tabloidNews,
    usNews,
  } = homePageNews;

  return (
    <div className="home-page fade-in">
      <ToastContainer />
      {renderUniqueModal()}
      <TopNews
        stories={topNews}
        isAdmin={isAdmin && displayMode === "admin"}
      />
      <Container className="right-rail">
        <Row>
          <Col md={8} sm={8}>
            <RecentNews
              stories={recentNews}
              onDeleteClick={handleStoryDeleted}
              isAdmin={isAdmin && displayMode === "admin"}
            />
            {renderCategoryNews(7, politicsNews)}
            <Row className="category-news-three-stories">
              <Col sm={6} md={6}>
                {renderCategoryNews(9, businessNews)}
              </Col>
              <Col sm={6} md={6}>
                {renderCategoryNews(12, worldNews)}
              </Col>
            </Row>
            {renderCategoryNews(6, techNews)}
          </Col>
          <Col md={4} sm={4} className="left-padding">
            <SearchBox />
            <GoogleAd adSlot={"5493026687"} maxHeight={"250px"} showTitle />
            <PopularViewedNews
              popularStories={mostPopularNews}
              viewedStories={mostViewedNews}
              onDeleteClick={handleStoryDeleted}
              isAdmin={isAdmin && displayMode === "admin"}
            />
            <Categories categories={categories} />
            {!isMobile && (
              <Sticky
                topOffset={-50}
                boundaryElement={".right-rail"}
                hideOnBoundaryHit={false}
              >
                <div className="sticky-google-ad-right">
                  <GoogleAd adSlot={"6477566567"} minHeight={"600px"} />
                </div>
              </Sticky>
            )}
            {isMobile && <GoogleAd adSlot={"6477566567"} minHeight={"150px"} />}
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col sm={12}>
            {!isMobile && <GoogleAd adSlot={"6423034787"} maxHeight={"90px"} />}
          </Col>
        </Row>
      </Container>
      <section className="all-category-inner">
        <Container>
          <Row>
            <Col md={4} sm={4}>
              {renderCategoryNews(2, sportsNews)}
            </Col>
            <Col md={4} sm={4}>
              {renderCategoryNews(4, entertainmentNews)}
            </Col>
            <Col md={4} sm={4}>
              {renderCategoryNews(11, alternativeNews)}
            </Col>
          </Row>
          <Row>
            <Col md={4} sm={4}>
              {renderCategoryNews(1, usNewspaperNews)}
            </Col>
            <Col md={4} sm={4}>
              {renderCategoryNews(16, tabloidNews)}
            </Col>
            <Col md={4} sm={4}>
              {renderCategoryNews(10, usNews)}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default withRouter(HomePage);
