import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import Iframe from 'react-iframe';
import { URL } from '../../../../shared/utils';

import './ViewNewsletterModal.css';

const propTypes = {
  newsletterId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

const defaultProps = {
  isOpen: false,
  onClose: () => { }
}

const ViewNewsletterModal = (props) => {
  const {
    newsletterId,
    isOpen,
    onClose
  } = props;

  const customStyles = {
    content: {
      top: '10%',
      left: '10%',
      right: '10%',
      bottom: '10%',
      // padding: '30px',
      // marginRight: '-50%',
      width: '100%',
      // transform: 'translate(-50%, -50%)'
    }
  };

  // if (isMobile) {
  //   customStyles.content.maxHeight = '85%';
  // }

  return (
    <ReactModal
      isOpen={isOpen}
      // style={customStyles}
      // className="preview-modal"
      // overlayClassName="preview-modal-overlay"
      appElement={document.getElementById('root')}
    >
      <div className="preview-modal-container">
        <div className="close-button" onClick={onClose}>X</div>
        {!newsletterId && <>Loading...</>}
        {newsletterId &&
          <Iframe
            className="iframe"
            url={`${URL.baseUrl}newsletter/view?newsletterId=${newsletterId}&email=test@test.com=&emailServiceProvider=5&getLatest=true`}
          />
        }
      </div>
    </ReactModal>
  )
}

ViewNewsletterModal.propTypes = propTypes;
ViewNewsletterModal.defaultProps = defaultProps;

export default ViewNewsletterModal;