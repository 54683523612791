import React from 'react';
import PropTypes from 'prop-types';
import HeroCarousel from './heroCarousel';
import { Container, Row, Col } from 'react-bootstrap';

import NewsBox from '../../shared/newsBox/NewsBox';
import './TopNews.css';

const propTypes = {
  stories: PropTypes.array,
  onCommentClick: PropTypes.func,
  isAdmin: PropTypes.bool
}

const defaultProps = {
  stories: [],
  onCommentClick: () => { },
  isAdmin: false
}

const TopNews = (props) => {
  const { stories, onCommentClick, isAdmin } = props;

  if (stories.length === 0) {
    return null;
  }

  const heroes = stories.slice(0, stories.length - 4);
  const others = stories.slice(stories.length - 4, stories.length)

  return (
    <div className="top-news">
      <section className="news-feed paddb">
        <Container>
          <Row className="row-margin">
            <Col xs={12} md={6} sm={6} className="col-padding">
              <div id="content-slide" className="owl-carousel owl-theme">
                <HeroCarousel
                  heroStories={heroes}
                  onCommentClick={onCommentClick}
                  isAdmin={isAdmin}
                />
              </div>
            </Col>
            <Col md={6} sm={6} div className="d-none d-sm-block col-padding-1">
              <section>
                <Row>
                  {others.map(story => (
                    <Col md={6} sm={6} className="padd">
                      <NewsBox
                        story={story}
                        titlePosition={'below'}
                        showCategoryBadge
                        titleSize={'medium'}
                        onCommentClick={onCommentClick}
                        useImageUrl
                        lazyLoadImage={false}
                        showDeleteButton={false}
                        // showSiteLogo={false}
                        // showGradientOverlay
                        isAdmin={isAdmin}
                      />
                    </Col>
                  ))}
                </Row>
              </section>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

TopNews.propTypes = propTypes;
TopNews.defaultProps = defaultProps;

export default TopNews;