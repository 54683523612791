import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router";

import DataGrid from "react-data-grid";
import { ProgressBar, OverlayTrigger, Tooltip } from "react-bootstrap";
import MultiProgress from "react-multi-progress";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Container, Row, Col } from "react-bootstrap";

import * as newsletterActions from "../../../redux/actions/newsletterActions";

import ViewNewsletterModal from "./viewNewsletterModal";
import NewsletterFailsModal from "./newsletterFailsModal";

import "./NewsletterActivityPage.css";

function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + "h " : "";
  var mDisplay = m > 0 ? m + "m " : "";
  var sDisplay = s > 0 ? s + "s" : "";

  return hDisplay + mDisplay + sDisplay;
}

const autoRefreshInterval = 1000;

const defaultColumnProperties = {
  sortable: true,
};

const NumberFormatter = (value) => {
  return value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const RateFormatter = (row) => {
  const { nbrRequests, nbrDelivered, nbrFailed, deliveryRate } = row;

  if (!deliveryRate) return "...";

  let nbrToDeliver = nbrRequests - (nbrDelivered + nbrFailed);

  if (nbrToDeliver === 0) return "--";

  return `${deliveryRate.toFixed(1)}/s`;
};

const OpenVisitPctFormatter = (isp, value, pct) => {
  if (!pct && pct !== 0) return <div />;

  let variant = "success";

  if (pct < 75) variant = "warning";
  if (pct < 25) variant = "danger";

  return (
    <>
      <OverlayTrigger
        key={isp}
        placement={"left"}
        overlay={
          <Tooltip id={`tooltip-open-visit-${isp}`}>
            {pct.toFixed(0)}%<br />
          </Tooltip>
        }
      >
        <ProgressBar
          now={pct}
          variant={variant}
          label={pct.toFixed(1) === "100.0" ? "100%" : NumberFormatter(value)}
        />
      </OverlayTrigger>
    </>
  );
};

const EtdFormatter = (row) => {
  const {
    nbrRequests,
    nbrDelivered,
    nbrFailed,
    deliveryRate,
    estTimeOfDelivery,
  } = row;

  const nbrToDeliver = nbrRequests - (nbrDelivered + nbrFailed);

  if (!deliveryRate && nbrToDeliver > 0) {
    return "∞";
  }

  if (!estTimeOfDelivery) return "--";

  return secondsToHms(estTimeOfDelivery);
};

const InboxPlacementFormatter = (row) => {
  const { isp, inboxPct, spamPct, missingPct } = row;

  if (!inboxPct && !spamPct && !missingPct) return <div />;

  return (
    <OverlayTrigger
      key={isp}
      placement={"top"}
      overlay={
        <Tooltip id={`tooltip-${isp}`}>
          {inboxPct?.toFixed(1) || "n/a"}% inbox
          <br />
          {spamPct?.toFixed(1) || "n/a"}% spam
          <br />
          {missingPct?.toFixed(1) || "n/a"}% missing
          <br />
        </Tooltip>
      }
    >
      <div
        style={{
          marginTop: "12px",
        }}
      >
        <MultiProgress
          height={10}
          elements={[
            {
              value: inboxPct,
              color: "#28a745",
            },
            {
              value: spamPct,
              color: "#ffc107",
            },
            {
              value: missingPct,
              color: "#dc3545",
            },
          ]}
        />
      </div>
    </OverlayTrigger>
  );
};

const ActivityFormatter = (row) => {
  const {
    isp,
    nbrRequests,
    nbrDelivered,
    nbrQueued,
    nbrFailed,
    onFailsClicked,
  } = row;

  const deliveredPct = (nbrDelivered / nbrRequests) * 100;
  const queuedPct = (nbrQueued / nbrRequests) * 100;
  const failedPct = (nbrFailed / nbrRequests) * 100;

  return (
    <a
      href="javascript:void(0)"
      onClick={() => {
        onFailsClicked && onFailsClicked(isp);
      }}
    >
      <OverlayTrigger
        key={isp}
        placement={"top"}
        overlay={
          <Tooltip id={`tooltip-${isp}`}>
            {NumberFormatter(nbrFailed)} failed
            <br />
            {NumberFormatter(nbrDelivered)} delivered
            <br />
            {NumberFormatter(nbrQueued)} queued
            <br />
          </Tooltip>
        }
      >
        <div
          style={{
            marginTop: "12px",
          }}
        >
          <MultiProgress
            height={10}
            backgroundColor="darkgray"
            elements={[
              {
                value: failedPct,
                color: "#fc6c85",
              },
              {
                value: deliveredPct,
                color: "lightGreen",
              },
              {
                value: queuedPct,
                color: "lightBlue",
              },
            ]}
          />
        </div>
      </OverlayTrigger>
    </a>
  );
};

const columns = [
  {
    key: "isp",
    name: "ISP",
    width: 130,
    summaryFormatter() {
      return <strong>Totals:</strong>;
    },
  },
  {
    key: "nbrRequests",
    name: "Requests",
    formatter({ row, column }) {
      return NumberFormatter(row[column.key]);
    },
    summaryFormatter({ row }) {
      return <>{NumberFormatter(row.nbrRequests)}</>;
    },
  },
  {
    key: "nbrDelivered",
    name: "Send Activity",
    width: 200,
    formatter({ row }) {
      return ActivityFormatter(row);
    },
    summaryFormatter({ row }) {
      return ActivityFormatter(row);
    },
  },
  {
    key: "deliveryRate",
    name: "Speed",
    formatter({ row }) {
      return RateFormatter(row);
    },
    summaryFormatter({ row }) {
      return <>{RateFormatter(row)}</>;
    },
  },
  {
    key: "estTimeOfDelivery",
    name: "ETD",
    formatter({ row }) {
      return EtdFormatter(row);
    },
    summaryFormatter({ row }) {
      return <>{EtdFormatter(row)}</>;
    },
  },
  {
    key: "nbrOfOpens",
    name: "Opens",
    formatter({ row }) {
      const { isp, nbrOfOpens, nbrDelivered } = row;

      var openPct = (nbrOfOpens / nbrDelivered) * 100;

      row.openPct = openPct;

      return OpenVisitPctFormatter(isp, nbrOfOpens, openPct);
    },
    summaryFormatter({ row }) {
      return <>{OpenVisitPctFormatter(row.isp, row.nbrOfOpens, row.openPct)}</>;
    },
  },
  {
    key: "nbrOfVisits",
    name: "Visits",
    formatter({ row }) {
      const { isp, nbrOfOpens, nbrOfVisits } = row;

      var visitPct = (nbrOfVisits / nbrOfOpens) * 100;

      row.visitPct = visitPct;

      return OpenVisitPctFormatter(isp, nbrOfVisits, visitPct);
    },
    summaryFormatter({ row }) {
      return (
        <>{OpenVisitPctFormatter(row.isp, row.nbrOfVisits, row.visitPct)}</>
      );
    },
  },
  {
    key: "inboxPct",
    name: "Inbox Placement",
    width: 150,
    formatter({ row }) {
      return InboxPlacementFormatter(row);
    },
    summaryFormatter({ row }) {
      return InboxPlacementFormatter(row);
    },
  },
].map((c) => ({ ...c, ...defaultColumnProperties }));

const NewsletterActivityPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { auth, newsletters, ispActivity, fails } = useSelector((state) => ({
    auth: state.auth,
    newsletters: state.newsletter.newsletters,
    ispActivity: state.newsletter.ispActivity,
    fails: state.newsletter.fails,
  }));

  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [saved, setSaved] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [activityRows, setActivityRows] = useState([]);
  const [totalsRow, setTotalsRow] = useState({});
  const [selectedNewsletterId, setSelectedNewsletterId] = useState(null);
  const [selectedEsp, setSelectedEsp] = useState(null);
  const [viewNewsletterModalOpen, setViewNewsletterModalOpen] = useState(false);
  const [newsletterFailsModalOpen, setNewsletterFailsModalOpen] =
    useState(false);
  const [currentSort, setCurrentSort] = useState({
    sortColumn: "nbrDelivered",
    sortDirection: "DESC",
  });

  const [sortColumns, setSortColumns] = useState([]);
  const [getIspActivityTime, setGetIspActivityTime] =
    useState(autoRefreshInterval);

  const [gettingIspActivity, setGettingIspActivity] = useState(false);

  const [prevIspActivity, setPrevIspActivity] = useState([]);

  const [time, setTime] = React.useState(0);

  useEffect(() => {
    if (autoRefresh) {
      const timer = setTimeout(() => {
        getNewsletterIspActivity();
        setTime(time + 1);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [autoRefresh, time]);

  useEffect(() => {
    getNewsletters();
  }, []);

  useEffect(() => {
    updateIspActivity(ispActivity, prevIspActivity);
  }, [ispActivity]);

  useEffect(() => {
    // if (newsletters.length > 0) {
    getNewsletterIspActivity();
    //}
  }, [newsletters]);

  const toggleAutoRefresh = () => {
    setAutoRefresh(!autoRefresh);
  };

  const handleFailsClicked = (isp) => {
    dispatch(
      newsletterActions.getNewsletterFails(
        isp,
        selectedNewsletterId,
        auth.token
      )
    )
      .catch((err) => {
        console.log(err.message);

        history.push({
          pathname: "/login",
          state: { redirect: "/admin/newsletterActivity" },
        });
      })
      .finally(() => {
        setNewsletterFailsModalOpen(true);
      });
  };

  const updateIspActivity = (ispActivity, prevIspActivity) => {
    let activityRows = [];

    let totalsRow = {
      isp: "Totals:",
      nbrRequests: 0,
      nbrQueued: 0,
      nbrDelivered: 0,
      deliveredPct: 0,
      deliveryRate: 0,
      estTimeOfDelivery: 0,
      nbrFailed: 0,
      nbrOfOpens: 0,
      openPct: 0,
      nbrOfVisits: 0,
      visitPct: 0,
      nbrOfAdClicks: 0,
      totalInboxPct: 0,
      totalMissingPct: 0,
      totalSpamPct: 0,
      totalInboxIsps: 0,
      inboxPct: 0,
    };

    ispActivity.forEach((item) => {
      const {
        nbrRequests,
        nbrQueued,
        nbrOfOpens,
        nbrOfVisits,
        nbrOfAdClicks,
        inboxPct,
        spamPct,
        missingPct,
      } = item;

      if (item.nbrDelivered + item.nbrFailed > nbrRequests) {
        if (item.nbrFailed > nbrRequests) {
          item.nbrFailed = nbrRequests;
        }

        item.nbrDelivered = nbrRequests - item.nbrFailed;
      }

      const { nbrDelivered, nbrFailed } = item;

      item.onFailsClicked = handleFailsClicked;
      item.deliveredPct = (nbrDelivered / nbrRequests) * 100;
      item.deliveryRate = 0;
      item.estTimeOfDelivery = 0;

      if (autoRefresh) {
        var prevItem = prevIspActivity.find((p) => p.isp === item.isp);

        if (prevItem && prevItem.nbrDelivered && nbrDelivered) {
          var deliveryDiff = nbrDelivered - prevItem.nbrDelivered;

          var time = getIspActivityTime;
          if (time < autoRefreshInterval) {
            time = autoRefreshInterval;
          }

          var deliveryRate = (deliveryDiff / time) * 1000;

          item.deliveryRate = deliveryRate;
        }
      }

      if (item.deliveryRate) {
        let requestsToDeliver = nbrRequests - (nbrDelivered + nbrFailed);

        let secondsToDeliver = requestsToDeliver / deliveryRate;

        item.estTimeOfDelivery = secondsToDeliver;
      }

      item.openPct = (nbrOfOpens / nbrDelivered) * 100;
      item.visitPct = (nbrOfVisits / nbrDelivered) * 100;

      if (inboxPct !== null) {
        totalsRow.totalInboxIsps += 1;
        totalsRow.totalInboxPct += inboxPct;
        totalsRow.totalSpamPct += spamPct;
        totalsRow.totalMissingPct += missingPct;
      }

      totalsRow.nbrRequests += nbrRequests;
      totalsRow.nbrQueued += nbrQueued;
      totalsRow.nbrDelivered += nbrDelivered;
      totalsRow.nbrFailed += nbrFailed;
      totalsRow.nbrOfOpens += nbrOfOpens;
      totalsRow.nbrOfVisits += nbrOfVisits;
      totalsRow.nbrOfAdClicks += nbrOfAdClicks;

      totalsRow.deliveryRate += item.deliveryRate;

      activityRows.push({ ...item });
    });

    if (totalsRow.deliveryRate) {
      let totalRequestsToDeliver =
        totalsRow.nbrRequests - (totalsRow.nbrDelivered + totalsRow.nbrFailed);

      let totalSecondsToDeliver =
        totalRequestsToDeliver / totalsRow.deliveryRate;

      totalsRow.estTimeOfDelivery = totalSecondsToDeliver;
    }

    totalsRow.inboxPct = totalsRow.totalInboxPct / totalsRow.totalInboxIsps;
    totalsRow.spamPct = totalsRow.totalSpamPct / totalsRow.totalInboxIsps;
    totalsRow.missingPct = totalsRow.totalMissingPct / totalsRow.totalInboxIsps;

    setActivityRows([...activityRows]);

    totalsRow.deliveredPct =
      (totalsRow.nbrDelivered / totalsRow.nbrRequests) * 100;
    totalsRow.openPct = (totalsRow.nbrOfOpens / totalsRow.nbrDelivered) * 100;
    totalsRow.visitPct = (totalsRow.nbrOfVisits / totalsRow.nbrDelivered) * 100;

    setTotalsRow({ ...totalsRow });
    setPrevIspActivity([...ispActivity]);
  };

  const getNewsletters = () => {
    dispatch(newsletterActions.getNewsletters(auth.token))
      .catch((err) => {
        console.log(err.message);

        history.push({
          pathname: "/login",
          state: { redirect: "/admin/newsletterActivity" },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getNewsletterIspActivity = () => {
    if (!selectedNewsletterId) {
      return;
    }

    if (refreshing) return;

    const startTime = new Date();

    setRefreshing(true);

    dispatch(
      newsletterActions.getNewsletterIspActivity(
        selectedNewsletterId,
        selectedEsp,
        auth.token
      )
    )
      .catch((err) => {
        console.log(err.message);

        history.push({
          pathname: "/login",
          state: { redirect: "/admin/newsletterActivity" },
        });
      })
      .finally(() => {
        if (autoRefresh) {
          const endTime = new Date();

          setGetIspActivityTime(endTime - startTime);
        }

        setRefreshing(false);
      });
  };

  const renderViewNewsletterModal = () => {
    // let newsletterId = 0;

    // if (selectedNewsletterOption) {
    //   const { value } = selectedNewsletterOption;

    //   if (value) {
    //     newsletterId = value;
    //   }
    // }

    return (
      <ViewNewsletterModal
        newsletterId={selectedNewsletterId}
        isOpen={viewNewsletterModalOpen}
        onClose={handleViewNewsletterModalClose}
      />
    );
  };

  const handleViewNewsletterModalClose = () => {
    setViewNewsletterModalOpen(false);
  };

  const renderNewsletterFailsModal = () => {
    return (
      <NewsletterFailsModal
        fails={fails}
        isOpen={newsletterFailsModalOpen}
        onClose={handleNewsletterFailsModalClose}
      />
    );
  };

  const handleNewsletterFailsModalClose = () => {
    setNewsletterFailsModalOpen(false);
  };

  const viewNewsletter = () => {
    setViewNewsletterModalOpen(true);
  };

  const rowGetter = (i) => {
    if (i > activityRows.length - 1) {
      return totalsRow;
    }

    let row = { ...activityRows[i] };

    return row;
  };

  const sortRows = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };

    let sortedRows =
      sortDirection === "NONE"
        ? [...activityRows]
        : [...activityRows].sort(comparer);

    setActivityRows([...sortedRows]);
    setCurrentSort({ sortColumn, sortDirection });
  };

  const getComparator = (sortColumn) => {
    switch (sortColumn) {
      case "isp":
        return (a, b) => a[sortColumn].localeCompare(b[sortColumn]);
      case "nbrRequests":
      case "totalProcessed":
      case "queuedPct":
      case "deliveredPct":
      case "estTimeOfDelivery":
      case "failedPct":
      case "nbrQueued":
      case "nbrDelivered":
      case "deliveryRate":
      case "nbrFailed":
      case "nbrOfOpens":
      case "openRate":
      case "nbrOfVisits":
      case "visitRate":
      case "nbrOfAdClicks":
      case "inboxPct":
      case "spamPct":
      case "openPct":
        return (a, b) => a[sortColumn] - b[sortColumn];
      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  };

  const sortedRows = () => {
    if (sortColumns.length === 0) return activityRows;

    const sortedRows = [...activityRows];

    sortedRows.sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === "ASC" ? compResult : -compResult;
        }
      }

      return 0;
    });

    return sortedRows;
  };

  const handleSortColumnChange = (columns) => {
    setSortColumns([...columns]);
  };

  const getNewsletterOptions = () => {
    let options = [];

    if (newsletters && newsletters.length > 0) {
      newsletters.forEach((n) => {
        options.push({ value: n.id, label: n.subject });
      });
    }

    return options;
  };

  const getSelectedNewsletterOption = () => {
    if (!selectedNewsletterId) {
      if (newsletters && newsletters.length > 0) {
        const defaultOption = {
          value: newsletters[0].id,
          label: newsletters[0].subject,
        };

        // console.log("defaultOption", defaultOption);

        setSelectedNewsletterId(defaultOption.value);

        return defaultOption;
      }
    }

    if (!newsletters || newsletters.length === 0) {
      return {};
    }

    var selectedNewsletter = newsletters.find(
      (n) => n.id === selectedNewsletterId
    );

    // console.log("selectedNewsletter", selectedNewsletter);

    return { value: selectedNewsletter.id, label: selectedNewsletter.subject };
  };

  const onNewsletterSelect = (option) => {
    //stopAutoRefresh();

    console.log("option", option);

    setSelectedNewsletterId(option.value);
  };

  return (
    <div className="newsletter-activity">
      <ToastContainer />
      {renderViewNewsletterModal()}
      {renderNewsletterFailsModal()}
      <Container>
        {/* <Row>
            <Col>
              <div style={{ height: '50px', width: '50px' }}>
                <ReactSpeedometer
                  fluidWidth={true}
                  value={3}
                  maxValue={10}
                  labelFontSize={0}
                // segments={5}
                // segmentColors={[
                //   "#bf616a",
                //   "#d08770",
                //   "#ebcb8b",
                //   "#a3be8c",
                //   "#b48ead",
                // ]}
                />
              </div>
            </Col>
          </Row> */}
        <Row>
          <Col>
            <Dropdown
              className="newsletter-dropdown"
              options={getNewsletterOptions()}
              onChange={onNewsletterSelect}
              value={getSelectedNewsletterOption()}
              placeholder="Select a newsletter"
            />
            <button className="btn btn-style send" onClick={viewNewsletter}>
              View
            </button>
            <div className="auto-refresh">
              <Checkbox
                defaultChecked={autoRefresh}
                onChange={toggleAutoRefresh}
              />
              &nbsp;Auto-Refresh
            </div>
            <button
              className="btn btn-style"
              onClick={getNewsletterIspActivity}
            >
              {refreshing && !autoRefresh ? "Refreshing..." : "Refresh"}
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <DataGrid
              loading={refreshing}
              rowKeyGetter={(row) => {
                return row.id;
              }}
              columns={columns}
              rows={sortedRows()}
              defaultColumnOptions={{
                sortable: true,
                // resizable: true
              }}
              // onRowsChange={handleRowChanged}
              sortColumns={sortColumns}
              onSortColumnsChange={handleSortColumnChange}
              summaryRows={[totalsRow]}
              className="activity-grid"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(NewsletterActivityPage);
